import React from "react";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import FilterSelectControl from "../FormControl/FilterSelectControl";
import AuditTypeSelect from "./AuditTypeSelect";
import AuditActionSelect from "./AuditActionSelect";
import CommonUtilities from "../Utilities/Common";
import moment from "moment";

import DateRangePicker from "rsuite/DateRangePicker";

var DateFormat = require("dateformat");
let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    let urlApiUsersFilter = "";
    let params = [];

    let extendFilter = "all";
    const objectId = props.match.params.id;

    if (
      window.location.pathname.startsWith(
        process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST + "/object"
      ) &&
      objectId !== null &&
      objectId !== null &&
      objectId !== ""
    ) {
      extendFilter = objectId;
    }

    if (loggedUser.role === "Processors") {
      params = [loggedUser.processorId, extendFilter];

      urlApiUsersFilter =
        GlobalConfig.REACT_APP_API_AUDIT_URL +
        "byprocessor/" +
        loggedUser.processorId;
    } else if (loggedUser.role === "Staffs") {
      params = [loggedUser.processorId, extendFilter];

      urlApiUsersFilter =
        GlobalConfig.REACT_APP_API_AUDIT_URL +
        "byprocessor/" +
        loggedUser.processorId +
        "/" +
        loggedUser._id;
    } else if (loggedUser.role === "Super Administrator") {
      params = [extendFilter];

      urlApiUsersFilter =
        GlobalConfig.REACT_APP_API_AUDIT_URL + "users/" + loggedUser._id;
    }
    this.state = {
      urlapidelete: GlobalConfig.REACT_APP_API_AUDIT_URL,
      urladd: "",
      urlapi: `${GlobalConfig.REACT_APP_API_AUDIT_URL}${
        loggedUser.role === "Super Administrator" ? "byadmin" : "byprocessor"
      }/`,
      urlApiUsersFilter: urlApiUsersFilter,
      urllist: process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST,
      title: "Audit Logs",
      pluraltitle: "Audit Logs",
      filterUser: "",
      filterAction: "",
      filterType: "",
      filterStartDate: "",
      filterEndDate: "",
      isHideFilter: extendFilter !== "" && extendFilter !== "all",
      pagination: true,
      showRefresh2: true,
      objectId: objectId,

      // filter
      params,

      //server paging
      isServerPaging: true,
      loading: false,
      totalRows: 0,
      currentPage: 1,
      perPage: parseInt(GlobalConfig.REACT_APP_PAGE_SIZE),
      paginationPerPage: parseInt(GlobalConfig.REACT_APP_PAGE_SIZE),
    };
  }

  async componentDidMount() {
    if (loggedUser.role == "Super Administrator") {
      this.getItemsServerPaging(this.state.currentPage);
      return;
    }
    const fetchItem = await fetch(
      GlobalConfig.REACT_APP_API_PROCESSOR_URL + loggedUser.processorId
    );
    const item = await fetchItem.json();
    if (item != null && item.result === "OK" && item.data) {
      if (
        loggedUser.role == "Processors" ||
        loggedUser.role == "Lab Users" ||
        loggedUser.role == "Staffs"
      ) {
        this.getItemsServerPaging(this.state.currentPage);
      } else {
        this.setState({
          useExtendBody: true,
        });
      }
    } else {
      window.showAlert(
        "Error",
        "Processor does not exist longer. Please switch to other processor and try again.",
        "error"
      );
    }
  }

  setColumns = () => {
    return [
      {
        name: "Version",
        selector: "version",
        sortable: false,
        center: true,
        cell: (row) => (
          <a
            target="_blank"
            href={this.state.urllist + "/" + row._id}
            className="on-default edit-row"
            title="Click here to view detail"
          >
            Version {row.version}
          </a>
        ),
        omit: this.state.isHideFilter === false,
      },
      {
        name: "Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return DateFormat(row.createdDate, "mm/dd/yyyy hh:MM:ss TT");
        },
        minWidth: "160px",
      },
      {
        name: "User",
        selector: "createdUser",
        sortable: false,
        left: true,
        cell: (row) => row.createdUser,
      },
      {
        name: "Action",
        selector: "action",
        sortable: false,
        center: true,
        cell: (row) => {
          return (
            <span
              style={{
                color:
                  row.action === "Delete" || row.action === "Remove Invite"
                    ? "red"
                    : row.action === "Create" ||
                      row.action === "Copy" ||
                      row.action === "Invite"
                    ? "green"
                    : "",
                // fontWeight: "bold",
              }}
            >
              {row.action}
            </span>
          );
        },
      },
      {
        name: "Type",
        selector: "type",
        sortable: false,
        center: true,
        omit: this.state.isHideFilter,
      },
      {
        name: "",
        cell: (row) => (
          <a
            target="_blank"
            href={this.state.urllist + "/" + row._id}
            className="on-default edit-row"
            title="Click here to view detail"
          >
            Detail
          </a>
        ),
      },
    ];
  };

  defaultTitle = () => {
    if (
      this.state.items &&
      this.state.items.length > 0 &&
      this.state.isHideFilter
    ) {
      return "Revision history of " + this.state.items[0].type;
    }
    return "Audit Logs ";
  };

  extendButtons = () => {
    return "";
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState(
      {
        [controlId]: value,
      },
      function callBack() {
        this.filterItems();
      }
    );
  };

  handleSearch = (event) => {
    if (event.target) {
      const id = event.target.name;
      this.setState(
        {
          [id]: event.target.value,
        },
        function callBack() {
          this.filterItems();
        }
      );
    }
  };

  filterItems = () => {
    const startDate =
      this.state.filterStartDate && this.state.filterStartDate !== ""
        ? this.state.filterStartDate
        : "all";
    const endDate =
      this.state.filterEndDate && this.state.filterEndDate !== ""
        ? this.state.filterEndDate
        : "all";

    if (
      (startDate != null &&
        startDate != undefined &&
        startDate != "" &&
        startDate != "all" &&
        !CommonUtilities.isValidDate(startDate)) ||
      (endDate != null &&
        endDate != undefined &&
        endDate != "" &&
        endDate != "all" &&
        !CommonUtilities.isValidDate(endDate))
    ) {
      window.showAlert("Error", "Format date must be MM/DD/YYYY", "error");
      return;
    }

    let sDate = undefined;
    let eDate = undefined;
    if (startDate !== "all") {
      sDate = moment(startDate + " 00:00:00", "MM/DD/YYYY HH:mm:ss");
    }
    if (endDate !== "all") {
      eDate = moment(endDate + " 23:59:59", "MM/DD/YYYY HH:mm:ss");
    }
    const startDateFilter = startDate !== "all" ? sDate : startDate;
    const endDateFilter = endDate !== "all" ? eDate : endDate;

    const createdUserId =
      this.state.filterUser && this.state.filterUser !== ""
        ? this.state.filterUser
        : "all";
    const filterAction =
      this.state.filterAction && this.state.filterAction !== ""
        ? this.state.filterAction
        : "all";
    const filterType =
      this.state.filterType && this.state.filterType !== ""
        ? this.state.filterType
        : "all";

    let urlapi = "";
    let params = [];
    let extendFilter = "all";
    const objectId = this.state.objectId;
    if (
      window.location.pathname.startsWith(
        process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST + "/object"
      ) &&
      objectId !== null &&
      objectId !== null &&
      objectId !== ""
    ) {
      extendFilter = objectId;
    }
    if (loggedUser.role === "Processors" || loggedUser.role === "Staffs") {
      params = [
        loggedUser.processorId,
        extendFilter,
        createdUserId,
        filterType,
        filterAction,
        startDateFilter,
        endDateFilter,
      ];
    } else if (loggedUser.role === "Super Administrator") {
      params = [
        extendFilter,
        createdUserId,
        filterType,
        filterAction,
        startDateFilter,
        endDateFilter,
      ];
    }

    this.setState({ currentPage: 1 }, () => {
      this.getItemsServerPaging(
        this.state.currentPage,
        this.state.perPage,
        params
      );
    });
  };

  renderSearch = () => {
    return (
      <>
        {(loggedUser.role !== "Super Administrator" &&
          loggedUser.role !== "Processors" &&
          loggedUser.role !== "Staffs" &&
          loggedUser.role !== "Lab Users") ||
        this.state.isHideFilter ? (
          ""
        ) : (
          <>
            <div className="form-search form-search--audit">
              <div className="col-filter">
                <FilterSelectControl
                  handleChangeSelectControl={this.handleChangeSelectControl}
                  onRef={(ref) => (this.growerControl = ref)}
                  placeholder=""
                  label="Filter Users"
                  controlId="filterUser"
                  urlapilist={this.state.urlApiUsersFilter}
                />
              </div>

              <div className="col-filter">
                <AuditTypeSelect
                  handleChangeSelectControl={this.handleChangeSelectControl}
                  onRef={(ref) => (this.filterType = ref)}
                  placeholder=""
                  label="Filter Types"
                  name="filterType"
                  userRole={loggedUser.role}
                />
              </div>

              <div className="col-filter">
                <AuditActionSelect
                  handleChangeSelectControl={this.handleChangeSelectControl}
                  onRef={(ref) => (this.filterAction = ref)}
                  placeholder=""
                  label="Filter Actions"
                  name="filterAction"
                />
              </div>

              <div className="col-filter">
                <DateRangePicker
                  onChange={(value) => {
                    const [from, to] = value;
                    this.setState(
                      {
                        filterStartDate: moment(from).format("MM/DD/YYYY"),
                        filterEndDate: moment(to).format("MM/DD/YYYY"),
                      },
                      function callBack() {
                        this.filterItems();
                      }
                    );
                  }}
                  showOneCalendar
                  renderValue={(value) => {
                    const [from, to] = value;
                    return (
                      <span>
                        {moment(from).format("MM/DD/YYYY")} -{" "}
                        {moment(to).format("MM/DD/YYYY")}
                      </span>
                    );
                  }}
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  renderRefresh2 = () => {
    return this.state.showRefresh2 ? (
      <div className="panel-actions panel-actions--audit">
        <a href="#" title="Refresh" onClick={() => this.handleRefresh()}>
          <i className="fa fa-refresh fa-lg"></i>
        </a>
      </div>
    ) : (
      ""
    );
  };
}
export default App;
