import React from "react";
import { getLoggedUser } from "../context/auth";
import NavigationAdmin from "./NavigationAdmin";

let loggedUser = {};

class App extends React.Component {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
  }

  renderSwitch() {
    switch (loggedUser.role) {
      case "Super Administrator":
        return (
          <NavigationAdmin navRoute={this.props.navRoute}></NavigationAdmin>
        );
      default:
        return "";
    }
  }

  render() {
    return (
      <aside id="sidebar-left" className="sidebar-left">
        <div className="sidebar-header">
          <div className="sidebar-title" style={{ color: "#abb4be" }}>
            Navigation
          </div>
          <div
            className="sidebar-toggle hidden-xs"
            data-toggle-class="sidebar-left-collapsed"
            data-target="html"
            data-fire-event="sidebar-left-toggle"
          >
            <i className="fa fa-bars" aria-label="Toggle sidebar"></i>
          </div>
        </div>

        <div className="nano">
          <div className="nano-content">
            <nav id="menu" className="nav-main" role="navigation">
              {this.renderSwitch()}
            </nav>
          </div>
        </div>
      </aside>
    );
  }
}
export default App;
