import React from "react";

const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    const {
      totalAdmins,
      totalLecturers,
      totalStudents,
      activeStudents,
      inactiveStudents,
    } = this.props.data;

    return (
      <section className="panel">
        <header className="panel-heading">
          <div className="panel-actions">
            <a href="#" className="fa fa-caret-down"></a>
          </div>
          <h2 className="panel-title">Summary</h2>
        </header>
        <div className="panel-body">
          <div className="col-md-6 col-lg-12 col-xl-6">
            <div className="row">
              <div className="col-md-3">
                <section className="panel panel-featured-left panel-featured-tertiary">
                  <div className="panel-body">
                    <div className="widget-summary">
                      <div className="widget-summary-col widget-summary-col-icon">
                        <div className="summary-icon bg-tertiary">
                          <i className="fa fa-users"></i>
                        </div>
                      </div>
                      <div className="widget-summary-col">
                        <div className="summary">
                          <h4 className="title">Number of Students</h4>
                          <div className="info">
                            <strong className="amount">{totalStudents}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-md-3">
                <section className="panel panel-featured-left panel-featured-tertiary total-active-students-panel">
                  <div className="panel-body">
                    <div className="widget-summary">
                      <div className="widget-summary-col widget-summary-col-icon">
                        <div className="summary-icon bg-tertiary total-active-students-icon">
                          <i className="fa fa-users"></i>
                        </div>
                      </div>
                      <div className="widget-summary-col">
                        <div className="summary">
                          <h4 className="title">Active Students</h4>
                          <div className="info">
                            <strong className="amount">{activeStudents}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-md-3">
                <section className="panel panel-featured-left panel-featured-tertiary total-inactive-students-panel">
                  <div className="panel-body">
                    <div className="widget-summary">
                      <div className="widget-summary-col widget-summary-col-icon">
                        <div className="summary-icon bg-tertiary total-inactive-students-icon">
                          <i className="fa fa-users"></i>
                        </div>
                      </div>
                      <div className="widget-summary-col">
                        <div className="summary">
                          <h4 className="title">Inactive Students</h4>
                          <div className="info">
                            <strong className="amount">
                              {inactiveStudents}
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {/* <div className="col-md-4">
                <section className="panel panel-featured-left panel-featured-primary">
                  <div className="panel-body">
                    <div className="widget-summary">
                      <div className="widget-summary-col widget-summary-col-icon">
                        <div className="summary-icon bg-primary">
                          <i className="fa fa-users"></i>
                        </div>
                      </div>
                      <div className="widget-summary-col">
                        <div className="summary">
                          <h4 className="title">Number of Lecturers</h4>
                          <div className="info">
                            <strong className="amount">
                              {totalLecturers}
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-md-4">
                <section className="panel panel-featured-left panel-featured-quartenary">
                  <div className="panel-body">
                    <div className="widget-summary">
                      <div className="widget-summary-col widget-summary-col-icon">
                        <div className="summary-icon bg-quartenary">
                          <i className="fa fa-users"></i>
                        </div>
                      </div>
                      <div className="widget-summary-col">
                        <div className="summary">
                          <h4 className="title">Number of Students</h4>
                          <div className="info">
                            <strong className="amount">
                              {totalStudents}
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default App;
