import React from "react";
import AddComponent from "../Base/AddComponent";
import { getLoggedUser } from "../../context/auth";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { Helmet } from "react-helmet";
import CommonUtilities from "../Utilities/Common";
import { USER_ROLE_STUDENT } from "../Utilities/Constant";
import SelectConfig from "../Base/SelectConfig";
import Switch from "react-switch";
import { Button } from "react-bootstrap";
import AsyncSelect from "../Base/AsyncSelect";
import { CSVLink } from "react-csv";
const XLSX = require("xlsx");

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends AddComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    this.state = {
      title: "User",
      listtitle: "Manage Users",
      urllist: process.env.REACT_APP_URL_USER_LIST,
      urlapi: GlobalConfig.REACT_APP_API_USER_URL,
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      data: {
        _id: "",
        role: params?.role || "",
        name: "",
        email: "",
        userId: "",
        matriculationNumber: "",
        classId: [],
        status: "Active",
        password: "",
        avatar: "",
        avatarExtension: "",
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
        baseUrl: process.env.REACT_APP_BASE_URL,
        fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
      },
      errors: {
        email: "",
      },
      //This state is used for import students from csv
      importMode: false,
      importFiles: [],
    };
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
  }

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      data: Object.assign({}, this.state.data, {
        [controlId]: value,
      }),
    });
  };

  checkSomethingBeforeSave = () => {
    const isValidEmail = CommonUtilities.isValidEmail(this.state.data.email);
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        email: isValidEmail,
      }),
    });
    if (isValidEmail && isValidEmail !== "") {
      this.email.focus();
      return false;
    } else {
      return true;
    }
  };

  getTitle = () => {
    return this.state.title;
  };

  handResetForm = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    this.setState({
      data: {
        _id: "",
        role: params?.role || "",
        name: "",
        email: "",
        userId: "",
        matriculationNumber: "",
        classId: "",
        status: "Active",
        password: "",
        avatar: "",
        avatarExtension: "",
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
        baseUrl: process.env.REACT_APP_BASE_URL,
        fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
      },
      errors: {
        email: "",
      },
    });
    this.role.setSelected(params?.role || "");
  };

  doSomethingAfterSaved = () => {
    this.nameInput && this.nameInput.focus();
  };

  onChangeImportMode = (toggle) => {
    //If turn off import mode then reset chose files
    if (!toggle) {
      this.resetImportFiles();
    }
    this.setState({
      importMode: toggle,
    });
  };

  resetImportFiles = () => {
    this.setState({
      importFiles: [],
    });
    document.getElementById("import-users-file-selector").value = "";
  };

  onChangeFiles = (e) => {
    this.setState({
      importFiles: e.target.files,
    });
  };

  onChangeStatus = (toggle) => {
    const status = toggle ? "Active" : "Inactive";
    this.setState({
      data: { ...this.state.data, status: status },
    });
  };

  import = async () => {
    try {
      if (this.state.importFiles.length === 0) {
        window.showAlert(
          "WARNING",
          this.state.importMode
            ? "Please upload file to import."
            : "Please turn on Import CSV and upload file to import.",
          "error"
        );
        return;
      }
      const file = this.state.importFiles[0];
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(file);
      fileReader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const records = XLSX.utils.sheet_to_json(worksheet);
        if (!records.length > 0) {
          window.showAlert(
            "ERROR",
            "The file has no data to import. Please upload other file.",
            "error"
          );
          this.resetImportFiles();
          return;
        }
        const users = this.getUsersFromCsv(records);
        this.bulkInsertUsers(users);
      };
    } catch (exception) {
      window.showAlert("ERROR", exception, "error");
    }
  };

  getUsersFromCsv = (records) => {
    return records.map((record) => {
      return {
        userId: record["User ID"],
        name: record["Name"],
        email: record["Email"],
        role: record["Role"] ? record["Role"] : USER_ROLE_STUDENT,
        createdUserId: loggedUser._id,
      };
    });
  };

  bulkInsertUsers = (users) => {
    fetch(`${this.state.urlapi}bulkinsert`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(users),
    })
      .then((res) => res.json())
      .then((responseData) => {
        if (responseData.result === "OK") {
          window.showAlert(
            "Congratulation",
            `${responseData.data} Student(s) have been saved.`
          );
        } else {
          window.showAlert(responseData.result, responseData.message, "error");
        }
        this.resetImportFiles();
      })
      .catch((exception) => {
        window.showAlert("ERROR", exception, "error");
      });
  };

  renderDownloadSampleCSV = () => {
    const header = [
      { label: "User ID", key: "userId" },
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
    ];
    const data = [
      { userId: "student1", name: "Student 1", email: "" },
      { userId: "student2", name: "Student 2", email: "" },
      { userId: "student3", name: "Student 3", email: "student3@gmail.com" },
      { userId: "student4", name: "Student 4", email: "student4@gmail.com" },
    ];
    return (
      <CSVLink
        filename={"sample.csv"}
        data={data}
        headers={header}
        className="sample-csv-download"
      >
        sample.csv
      </CSVLink>
    );
  };

  render() {
    const title = "Register a new " + this.getTitle();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation navRoute={this.props.navRoute}></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.state.urllist}>{this.state.listtitle}</a>
                    </li>
                    <li>
                      <span>{this.getTitle()}</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>

              <div className="row">
                <div className="col-md-8 col-lg-6">
                  <form
                    className="form-horizontal form-bordered"
                    onSubmit={this.handleSubmit}
                    id="form"
                  >
                    <div className="panel">
                      <div className="panel-body">
                        <fieldset>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">
                              User ID <span className="required">(*)</span>
                            </label>
                            <div className="col-md-9">
                              <input
                                maxLength="150"
                                type="text"
                                name="userId"
                                className="form-control"
                                placeholder="Enter User ID"
                                required
                                value={this.state.data.userId}
                                onChange={this.handleChange}
                                ref={(input) => {
                                  this.userIdInput = input;
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">
                              Role Type <span className="required">(*)</span>
                            </label>
                            <div className="col-md-9">
                              <SelectConfig
                                configType={
                                  process.env.REACT_APP_CONFIG_NAME_USER_ROLE
                                }
                                required={true}
                                onRef={(ref) => (this.role = ref)}
                                name="role"
                                controlId="role"
                                selectedIds={this.state.data.role}
                                // memo: default value
                                value={this.state.data.role}
                                handleChangeSelectControl={
                                  this.handleChangeSelectControl
                                }
                                exceptData={["Super Administrator"]}
                                label="Select a Role"
                              />
                              {/* <div className="help-block">
                                Select between an Admin (full control), Lecturer
                                (class and student management), or
                                Student(mobile app access)
                              </div> */}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              className="col-md-3 control-label"
                              htmlFor="name"
                            >
                              Full Name <span className="required">(*)</span>
                            </label>
                            <div className="col-md-9">
                              <input
                                maxLength="150"
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Enter Name"
                                required
                                value={this.state.data.name}
                                onChange={this.handleChange}
                                ref={(input) => {
                                  this.nameInput = input;
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              className="col-md-3 control-label"
                              htmlFor="email"
                            >
                              Email
                            </label>
                            <div className="col-md-9">
                              <input
                                maxLength="50"
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Enter Email"
                                value={this.state.data.email}
                                onChange={this.handleChange}
                                ref={(input) => {
                                  this.email = input;
                                }}
                              />
                              {this.state.errors.email &&
                              this.state.errors.email !== "" ? (
                                <label style={{ color: "#d2322d" }}>
                                  {this.state.errors.email}
                                </label>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label import-csv-label">
                              Active
                            </label>
                            <div className="col-md-9 flex-box align-items-center">
                              <Switch
                                onChange={this.onChangeStatus}
                                checked={
                                  this.state.data
                                    ? this.state.data.status === "Active"
                                      ? true
                                      : false
                                    : false
                                }
                                className="react-switch"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label import-csv-label">
                              Import CSV
                            </label>
                            <div className="col-md-9 flex-box align-items-center">
                              <Switch
                                name="isManager"
                                onChange={this.onChangeImportMode}
                                checked={this.state.importMode}
                                className="react-switch"
                              />
                              {this.state.importMode ? (
                                <input
                                  type="file"
                                  id="import-users-file-selector"
                                  accept=".xls,.xlsx"
                                  onChange={this.onChangeFiles}
                                />
                              ) : (
                                this.renderDownloadSampleCSV()
                              )}
                            </div>
                          </div>
                          {/* <div className="form-group row">
                            <label
                              className="col-md-3 control-label"
                              htmlFor="matriculationNumber"
                            >
                              Matriculation Number
                            </label>
                            <div className="col-md-9">
                              <input
                                name="matriculationNumber"
                                className="form-control"
                                value={this.state.data.matriculationNumber}
                                onChange={this.handleChange}
                                placeholder="Enter Matriculation Number"
                                ref={(input) => {
                                  this.matriculationNumber = input;
                                }}
                              />
                              {this.state.errors.matriculationNumber && (
                                <label style={{ color: "#d2322d" }}>
                                  {this.state.errors.matriculationNumber}
                                </label>
                              )}
                              <div className="help-block">
                                Alphanumeric characters as they appear on
                                membership, staff or matriculation cards
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              className="col-md-3 control-label"
                              htmlFor="matriculationNumber"
                            >
                              Class
                            </label>
                            <div className="col-md-9">
                              <AsyncSelect
                                urlapi={`${process.env.REACT_APP_API_CLASS_URL}queryall`}
                                onRef={(ref) => (this.classId = ref)}
                                name="classId"
                                controlId="classId"
                                selectedIds={this.state.data.classId}
                                handleChangeSelectControl={
                                  this.handleChangeSelectControl
                                }
                                label="Select a Class"
                                multiple
                              />
                              <div className="help-block">
                                Select a class to assign the student
                              </div>
                            </div>
                          </div> */}
                        </fieldset>
                        {/* <div
                          className={
                            this.state.data.role === "Student" ? "hide" : ""
                          }
                        >
                          <hr className="dotted tall"></hr>
                          <h4 className="mb-xlg" style={{ fontSize: "18px" }}>
                            Access Portal
                          </h4>
                          <fieldset className="mb-xl">
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label mt-xs pt-none"
                                htmlFor="status"
                              >
                                Status
                              </label>
                              <div className="col-md-9">
                                <select
                                  name="status"
                                  className="form-control"
                                  required
                                  value={this.state.data.status}
                                  onChange={this.handleChange}
                                >
                                  <option value="Active">Active</option>
                                  <option value="Inactive">Inactive</option>
                                </select>
                              </div>
                            </div>
                          </fieldset>
                        </div> */}
                      </div>
                      <div className="panel-footer" style={{ marginTop: 0 }}>
                        <div className="text-center">
                          <Button variant="warning" onClick={this.import}>
                            <i
                              className="fa fa-upload fa-lg"
                              aria-hidden="true"
                            ></i>
                            {" Import"}
                          </Button>
                          <button
                            type="submit"
                            className="btn btn-success"
                            disabled={this.state.importMode}
                          >
                            <i className="fa fa-save fa-lg"></i> Save
                          </button>
                          <a
                            href={
                              params?.role
                                ? `${this.state.urllist}?role=${params?.role}`
                                : this.state.urllist
                            }
                            title="Back to list"
                            className="btn btn-primary"
                          >
                            <i className="fa fa-backward fa-lg"></i> Back
                          </a>
                          {/* <a
                            href={
                              params?.role
                                ? `${this.state.urllist}-import`
                                : this.state.urllist
                            }
                            title="Import"
                            className="btn btn-primary"
                          >
                            <i className="fa fa-upload fa-lg"></i> Import
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-12 col-lg-3"></div>
              </div>
              {this.state.alertshow === true ? (
                <Alert
                  message={this.state.alertmsg}
                  type={this.state.alerttype}
                  show={this.state.alertshow}
                ></Alert>
              ) : (
                ""
              )}
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
