const rules = {
  "Processors": {
    static: [
      "tests",
      "lab-users",
      "handlers",
      "growers",
      "bills",
      "subscriptions",
      "growerpayments",
      "payment-settings",
      "base-prices",
      "bonus-deduct",
      "tax-fee",
      "audit-logs",
      "staffs",
      "grower-statements",
      "grower-payment-report",
      "attribute",
    ]
    // ,dynamic: {
    //   "growers": ({ id}) => {
    //     if (!id || !postOwnerId) return false;
    //     return userId === postOwnerId;
    //   }
    // }
  },
  "Super Administrator": {
    static: [
      "tests",
      "growers",
      "lab-users",
      "handlers",
      "bills",
      "subscriptions",
      "processors",
      "mail-templates",
      "membership-types",
      "classifications",
      "tiers",
      "administrators",
      "system-settings",
      "growerpayments",
      "payment-settings",
      "base-prices",
      "bonus-deduct",
      "tax-fee",
      "audit-logs",
      "staffs",
      "attribute",
      // memo: permission
      "users",
      "game-records",
    ]
  },
  "Administrator": {
    static: [
      "tests",
      "growers",
      "lab-users",
      "handlers",
      "bills",
      "subscriptions",
      "processors",
      "mail-templates",
      "membership-types",
      "classifications",
      "tiers",
      "administrators",
      "system-settings",
      "growerpayments",
      "payment-settings",
      "base-prices",
      "bonus-deduct",
      "tax-fee",
      "audit-logs",
      "staffs",
      "attribute",
      // memo: permission
      "users",
    ]
  },
  "Lecturer": {
    // memo: cannot access users module
    static: [
      "tests",
      "growers",
      "lab-users",
      "handlers",
      "bills",
      "subscriptions",
      "processors",
      "membership-types",
      "tiers",
      "administrators",
      "growerpayments",
      "payment-settings",
      "base-prices",
      "bonus-deduct",
      "tax-fee",
      "staffs",
      "attribute",
      // memo: permission
    ]
  }
};

export default rules;