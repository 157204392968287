import React, { useEffect, useState } from "react";
import EditComponent from "../Base/EditComponent";
import { getLoggedUser } from "../../context/auth";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends EditComponent {
  constructor(props) {
    super();
	loggedUser = getLoggedUser();
    this.state = {
      listtitle: "System Settings",
      urllist: process.env.REACT_APP_URL_SYSTEMCONFIG_LIST,
      urlapi: GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL,
      data: {
        _id: props.match.params.id,
        name: "",
        value: "",
        createdUserId: "",
        createdDate: null,
        modifiedUserId: loggedUser._id,
        modifiedDate: new Date(),
      },
    };
  }

  getTitle = () => {
    return this.state.data.name;
  };

  renderForm = () => {
    return (
      <div className="panel-body">
        <div className="form-group">
          <label className="col-sm-3 control-label">
            Name <span className="required">*</span>
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              name="name"
              autoFocus={true}
              className="form-control"
              placeholder=""
              required
              value={this.state.data.name}
              onChange={this.handleChange}
              maxLength={100}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-3 control-label">
            Value <span className="required">*</span>
          </label>
          <div className="col-sm-9">
            <textarea
              name="value"
              className="form-control"
              placeholder=""
              required
              value={this.state.data.value}
              onChange={this.handleChange}
              rows={10}
              maxLength={4000}
              data-plugin-markdown-editor
              rows={10}
            />
          </div>
        </div>
      </div>
    );
  };
}
export default App;
