import React, { useState } from "react";
import axios from "axios";
import { Error } from "../../Components/AuthForms";
import Alert from "../Utilities/Alert";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import moment from "moment";
import { getLoggedUser } from "../../context/auth";

var validator = require("email-validator");
const GlobalConfig = new window.globalConfig();

function App(props) {
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const loggedUser = getLoggedUser();

  const handleSetPassword = async () => {
    if (loggedUser) {
      const data = {
        _id: loggedUser?._id,
        newPassword,
        newPasswordRepeat
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };

      const response = await fetch(`${GlobalConfig.REACT_APP_API_AUTH_URL}/updatepassword`, requestOptions);
      const resultObject = await response.json();

      const { result, message } = resultObject;
      if (result === "OK") {
        window.showAlert("Success", message, "");
        props.history.push("/");
      } else {
        window.showAlert("Error", message, "Error");
      }
    }
  }

  return (
    <div
      style={{
        backgroundImage: "url(/assets/images/background-minds.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Helmet>
        <title>{"Change Password | " + process.env.REACT_APP_SITE_TITLE}</title>
      </Helmet>
      <div>
        <section className="body-sign">
          <div className="center-sign">
            <a href="/" className="logo pull-left pt-xl">
              <img
                src="/assets/images/logo-minds.png"
                height="30"
                alt={process.env.REACT_APP_SITE_TITLE}
              />
            </a>

            <div className="panel panel-sign">
              <div className="panel-title-sign mt-xl text-right">
                <h2 className="title text-uppercase text-bold m-none">
                  <i className="fa fa-user mr-xs"></i> Set Password
                </h2>
              </div>
              <div className="panel-body">
                <div className="form-group mb-lg">
                  <label>New Password</label>
                  <div className="input-group input-group-icon">
                    <input
                      required
                      name="newPassword"
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                      autoFocus
                      placeholder="Enter new password"
                      type="password"
                      className="form-control input-lg"
                    />
                    <span className="input-group-addon">
                      <span className="icon icon-lg">
                        <i className="fa fa-key"></i>
                      </span>
                    </span>
                  </div>
                </div>

                <div className="form-group mb-lg">
                  <label>New Password Reset</label>
                  <div className="input-group input-group-icon">
                    <input
                      required
                      name="newPasswordRepeat"
                      value={newPasswordRepeat}
                      onChange={(e) => {
                        setNewPasswordRepeat(e.target.value);
                      }}
                      autoFocus
                      placeholder="Enter new password"
                      type="password"
                      className="form-control input-lg"
                    />
                    <span className="input-group-addon">
                      <span className="icon icon-lg">
                        <i className="fa fa-key"></i>
                      </span>
                    </span>
                  </div>
                </div>

                <div className="text-right">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSetPassword}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>

            <p className="text-center text-muted mt-md mb-md">
              <span style={{ color: "white" }}>
                &copy; MINDS Serious Games
              </span>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
}
export default App;
