import React from "react";
import Dashboard from "./Dashboard";
import Header from "./Header";
import Navigation from "./Navigation";

function App({ navRoute }) {
  return (
    <section className="body">
      <Header></Header>
      <div className="inner-wrapper">
        <Navigation navRoute={navRoute}></Navigation>
        <Dashboard></Dashboard>
      </div>
    </section>
  );
}

export default App;
