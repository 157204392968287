import React from "react";
import DateFormat from "../Utilities/DateFormat";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import { Button } from "react-bootstrap";
import moment from "moment";
import CommonUtilities from "../Utilities/Common";
const FileSaver = require("file-saver");
const XLSX = require("xlsx");

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    this.state = {
      ...this.state,
      urlapi: `${GlobalConfig.REACT_APP_API_USER_URL}search/`,
      urllist: process.env.REACT_APP_URL_USER_LIST,
      urladd: process.env.REACT_APP_URL_USER_LIST + `-add?role=Student`,
      urlapidelete: GlobalConfig.REACT_APP_API_USER_URL,

      screenTitle: "Manage Users",
      title: params?.role || "Student",
      labeladd: "Add New User",

      // filter
      params: params?.role ? [params?.role] : ["Student"],
      filterRole: params?.role || "Student",
      filterName: "",

      //server paging
      isServerPaging: true,
      loading: false,
      totalRows: 0,
      currentPage: 1,
      perPage: parseInt(GlobalConfig.REACT_APP_PAGE_SIZE),
      paginationPerPage: parseInt(GlobalConfig.REACT_APP_PAGE_SIZE),

      // table
      selectableRows: true,
    };
  }

  handleFilter = (event) => {
    if (event.target) {
      const id = event.target.name;

      this.setState(
        {
          [id]: event.target.value,
          title: id === "filterRole" ? event.target.value : this.state.title,
        },
        function callBack() {
          this.filterItems();
        }
      );
    }
  };

  filterItems = () => {
    const { filterRole, filterName } = this.state;

    this.setState({
      urladd: filterRole
        ? `${process.env.REACT_APP_URL_USER_LIST}-add?role=${filterRole}`
        : `${process.env.REACT_APP_URL_USER_LIST}-add`,
    });

    this.setState({ params: [filterRole, filterName], currentPage: 1 }, () => {
      this.getItemsServerPaging(this.state.currentPage, this.state.perPage);
    });
  };

  setColumns = () => {
    const tblColumns = [
      {
        name: "User ID",
        selector: "userId",
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
        cell: (row) => {
          return loggedUser.referenceUserId === row._id ? (
            <a href={process.env.REACT_APP_URL_MY_PROFILE} title={row.name}>
              {row.name}
            </a>
          ) : (
            <a
              href={
                process.env.REACT_APP_URL_GAME_RECORD_LIST + "/" + row.userId
              }
              title={row.name}
            >
              {row.name}
            </a>
          );
        },
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
        left: true,
      },
      {
        name: "Status",
        sortable: true,
        center: true,
        cell: (row) => {
          return row.status === "Active" ? (
            <span
              className="label label-success"
              style={{ paddingTop: "5px", paddingBottom: "7px", width: "55px" }}
            >
              {row.status}
            </span>
          ) : (
            <span
              className="label label-danger"
              style={{ paddingTop: "5px", paddingBottom: "7px", width: "55px" }}
            >
              {row.status}
            </span>
          );
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return <DateFormat date={row.createdDate}></DateFormat>;
        },
      },
      {
        name: "",
        cell: (row) => {
          if (this.state.selectedRows.length) return null;

          return (
            <div>
              {loggedUser.referenceUserId === row._id ? (
                <a
                  href={process.env.REACT_APP_URL_MY_PROFILE}
                  className="on-default edit-row"
                  title="Update"
                >
                  <i className="fa fa-pencil-square-o fa-lg"></i>
                </a>
              ) : (
                <a
                  href={this.state.urllist + "/" + row._id}
                  className="on-default edit-row"
                  title="Update"
                >
                  <i className="fa fa-pencil-square-o fa-lg"></i>
                </a>
              )}
              &nbsp;&nbsp;
              {loggedUser.referenceUserId === row._id ? (
                ""
              ) : (
                <a
                  className="on-default remove-row"
                  data-id={row._id}
                  onClick={this.handleDelete.bind(this)}
                  title="Delete"
                  href="#"
                >
                  <i className="fa fa-trash-o fa-lg"></i>
                </a>
              )}
            </div>
          );
        },
      },
    ];

    if (this.state.filterRole === "Student") {
      return tblColumns.filter((item) => item.selector !== "status");
    }

    return tblColumns;
  };

  export = async () => {
    try {
      const result = await this.getExportData();
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const ws = XLSX.utils.json_to_sheet(result.exportData);
      XLSX.utils.sheet_add_aoa(ws, result.header);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], { type: fileType });
      const exportDate = moment(new Date()).format("YYYYMMDD");
      FileSaver.saveAs(data, `Record All_${exportDate}.xlsx`);
    } catch (exception) {
      window.showAlert(
        "ERROR",
        `An error occurred while exporting data. ${exception.message}`,
        "error"
      );
    }
  };

  getExportData = async () => {
    const exportUsers = [];
    let allGameRecords = [];
    const apiUrl = process.env.REACT_APP_API_GAMERECORD_URL;
    const users = [...this.state.items];
    for (let i = 0; i < users.length; i++) {
      const response = await fetch(`${apiUrl}${users[i].userId}`);
      const responseData = await response.json();
      let gameRecords = [];
      if (
        responseData.result === "OK" &&
        responseData.data &&
        Array.isArray(responseData.data)
      ) {
        gameRecords = responseData.data;
      }
      allGameRecords = allGameRecords.concat(gameRecords);
      exportUsers.push({
        ...users[i],
        gameRecords: gameRecords,
      });
    }
    const header = [
      [
        "UserID",
        "Name",
        "Status",
        "Scene",
        "Scene Title",
        "Level",
        "Start Time",
        "End Time",
        "Spent Time",
        "Saved Date",
      ].concat(CommonUtilities.getQuestionAndAnswerColumns(allGameRecords)),
    ];
    const exportData = [];
    exportUsers.forEach((user, userIndex) => {
      if (user.gameRecords && user.gameRecords.length) {
        user.gameRecords.forEach((gameRecord, index) => {
          const spentTime =
            Date.parse(gameRecord.endTime) - Date.parse(gameRecord.startTime);
          const row = {
            UserID: index === 0 ? user.userId : "",
            Name: index === 0 ? user.name : "",
            Status: index === 0 ? user.status : "",
            Scene: gameRecord.scene,
            "Scene Title": gameRecord.sceneTitle,
            Level: gameRecord.level,
            "Start Time": gameRecord.startTime,
            "End Time": gameRecord.endTime,
            "Spent Time": new Date(spentTime).toISOString().slice(11, 16),
            "Saved Date": moment(gameRecord.createdDate).format(
              "MM-DD-YYYY hh:mm A"
            ),
          };
          CommonUtilities.appendQuestionAndAnswerProperties(
            row,
            gameRecord.listOfQuestionsAndAnswers
          );
          exportData.push(row);
        });
      } else {
        //This will be a row for user doesn't has any game record in excel
        exportData.push({
          UserID: user.userId,
          Name: user.name,
          Status: user.status,
        });
      }
      if (userIndex < exportUsers.length) {
        //This will be an empty row in excel
        exportData.push({});
      }
    });
    return {
      exportData: exportData,
      header: header,
    };
  };

  renderSearch = () => {
    return (
      <div className="form-search">
        <div className="btn-group btn-group-justified">
          <div
            className={`form-check form-check-inline btn ${
              this.state.filterRole === "Student" ? "btn-info" : "btn-primary"
            }`}
          >
            <input
              name="filterRole"
              value="Student"
              type="radio"
              id="student"
              className="form-check-input hidden"
              checked={this.state.filterRole === "Student"}
              onChange={this.handleFilter}
            />
            <label title="" for="student" className="form-check-label">
              Student
            </label>
          </div>
          {/* <div
            className={`form-check form-check-inline btn ${
              this.state.filterRole === "Lecturer" ? "btn-info" : "btn-primary"
            }`}
          >
            <input
              name="filterRole"
              value="Lecturer"
              type="radio"
              id="lecturer"
              className="form-check-input hidden"
              checked={this.state.filterRole === "Lecturer"}
              onChange={this.handleFilter}
            />
            <label title="" for="lecturer" className="form-check-label">
              Lecturer
            </label>
          </div> */}
          {/* {loggedUser.role === "Super Administrator" && (
            <div
              className={`form-check form-check-inline btn ${this.state.filterRole === "Administrator" ? "btn-info" : "btn-primary"
                }`}
            >
              <input
                name="filterRole"
                value="Administrator"
                type="radio"
                id="Administrator"
                className="form-check-input hidden"
                checked={this.state.filterRole === "Administrator"}
                onChange={this.handleFilter}
              />
              <label title="" for="Administrator" className="form-check-label">
                Admin
              </label>
            </div>
          )} */}
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div class="input-group input-group-icon">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                name="filterName"
                onChange={this.handleFilter}
              />
              <span class="input-group-addon">
                <span class="icon">
                  <i class="fa fa-search"></i>
                </span>
              </span>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="text-right">
              <Button
                style={{ margin: 0 }}
                variant="warning"
                onClick={this.export}
              >
                <i className="fa fa-file-excel-o"></i> Export CSV
              </Button>
              {this.state.selectableRows && (
                <button
                  className="btn btn-danger"
                  data-id={this.state.selectedRows
                    .map(({ _id }) => _id)
                    .join(",")}
                  onClick={this.handleDelete.bind(this)}
                >
                  <i className="fa fa-trash-o fa-lg"></i>
                  <span className="ml-xs">Delete Selected</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
}
export default App;
