import React from "react";
import { getLoggedUser } from "../../context/auth";
import ProfileAdmin from "../../Components/Administrator/profile";

let loggedUser = {};

class App extends React.Component {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
  }

  renderSwitch() {
    switch (loggedUser.role) {
      case "Super Administrator":
      case "Administrator":
      case "Lecturer":
        return <ProfileAdmin />;
      default:
        return "";
    }
  }

  render() {
    return this.renderSwitch();
  }
}

export default App;
