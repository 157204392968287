import React from "react";
import { getLoggedUser } from "../context/auth";

class App extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    let pathName = window.location.pathname;
    const loggedUser = getLoggedUser();

    return (
      <ul className="nav nav-main">
        <li className={pathName === "" || pathName === "/" ? "nav-active" : ""}>
          <a title="DASHBOARD" href="/">
            <i className="fa fa-tachometer" aria-hidden="true"></i>
            <span>DASHBOARD</span>
          </a>
        </li>
        {loggedUser?.role && loggedUser?.role !== "Lecturer" ? (
          <li
            className={
              pathName.startsWith(process.env.REACT_APP_URL_USER_LIST)
                ? "nav-active"
                : ""
            }
          >
            <a
              title="USER MANAGEMENT"
              href={process.env.REACT_APP_URL_USER_LIST}
            >
              <i className="fa fa-users" aria-hidden="true"></i>
              <span>USER MANAGEMENT</span>
            </a>
          </li>
        ) : null}

        {loggedUser?.role && loggedUser?.role === "Super Administrator" ? (
          <>
            {/* <li className={pathName.startsWith(process.env.REACT_APP_URL_CLASSIFICATION_LIST) ? "nav-active" : ""}>
              <a title="CLASSIFICATIONS" href={process.env.REACT_APP_URL_CLASSIFICATION_LIST}>
                <i className="fa fa-cog" aria-hidden="true"></i>
                <span>CLASSIFICATIONS</span>
              </a>
            </li> */}
            <li
              className={
                pathName.startsWith(
                  process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST
                )
                  ? "nav-active"
                  : ""
              }
            >
              <a
                href={process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST}
                title="AUDIT LOGS"
              >
                <i className="fa fa-history" aria-hidden="true"></i>
                <span>AUDIT LOGS</span>
              </a>
            </li>
            <li
              className={
                pathName.startsWith(
                  process.env.REACT_APP_URL_MAIL_TEMPLATE_LIST
                )
                  ? "nav-active"
                  : ""
              }
            >
              <a
                title="MAIL TEMPLATES"
                href={process.env.REACT_APP_URL_MAIL_TEMPLATE_LIST}
              >
                <i className="fa fa-cogfa fa-envelope" aria-hidden="true"></i>
                <span>MAIL TEMPLATES</span>
              </a>
            </li>
            <li
              className={
                pathName.startsWith(process.env.REACT_APP_URL_SUPPER_USER_LIST)
                  ? "nav-active"
                  : ""
              }
            >
              <a
                title="ADMINISTRATORS"
                href={process.env.REACT_APP_URL_SUPPER_USER_LIST}
              >
                <i className="fa fa-cog" aria-hidden="true"></i>
                <span>ADMINISTRATORS</span>
              </a>
            </li>
            {/* <li
              className={
                pathName.startsWith(process.env.REACT_APP_URL_SYSTEMCONFIG_LIST)
                  ? "nav-active"
                  : ""
              }
            >
              <a
                title="SYSTEM SETTINGS"
                href={process.env.REACT_APP_URL_SYSTEMCONFIG_LIST}
              >
                <i className="fa fa-cog" aria-hidden="true"></i>
                <span>SYSTEM SETTINGS</span>
              </a>
            </li> */}
          </>
        ) : null}
      </ul>
    );
  }
}
export default App;
