import React from "react";
import AddComponent from "../Base/AddComponent";
import { getLoggedUser } from "../../context/auth";
// import FileBase64 from "react-file-base64";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { Helmet } from "react-helmet";
import CommonUtilities from "../Utilities/Common";
import SelectConfig from "../Base/SelectConfig";
import AsyncSelect from "../Base/AsyncSelect";
const axios = require('axios').default;

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends AddComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    this.state = {
      title: "Import User",
      listtitle: "Import Users",
      urllist: process.env.REACT_APP_URL_USER_LIST,
      urlapi: GlobalConfig.REACT_APP_API_USER_URL,
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      importedUser: [],
      duplicateUser: [],
      data: {
        _id: "",
        role: params?.role || "",
        name: "",
        email: "",
        matriculationNumber: "",
        classId: [],
        status: "Active",
        password: "",
        avatar: "",
        avatarExtension: "",
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
        baseUrl: process.env.REACT_APP_BASE_URL,
        fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
      },
      errors: {
        email: "",
      },
    };
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
  }

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      data: Object.assign({}, this.state.data, {
        [controlId]: value,
      }),
    });
  };

  checkSomethingBeforeSave = () => {
    const isValidEmail = CommonUtilities.isValidEmail(this.state.data.email);
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        email: isValidEmail,
      }),
    });
    if (isValidEmail && isValidEmail !== "") {
      this.email.focus();
      return false;
    } else {
      return true;
    }
  };

  getTitle = () => {
    return this.state.title;
  };

  handResetForm = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    this.setState({
      data: {
        _id: "",
        role: params?.role || "",
        name: "",
        email: "",
        matriculationNumber: "",
        classId: "",
        status: "Active",
        password: "",
        avatar: "",
        avatarExtension: "",       
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
        baseUrl: process.env.REACT_APP_BASE_URL,
        fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
      },
      selectedFile: null,
      errors: {
        email: "",
      },
    });
    this.role.setSelected(params?.role || "");
  };

  doSomethingAfterSaved = () => {
    this.nameInput && this.nameInput.focus();
  };

  onFileChange = event => {    
    this.setState({ selectedFile: event.target.files[0] });  
  };

  handleImportSubmit = async (e) => {
    e.preventDefault();
    if (this.checkSomethingBeforeSave() === false) {
        return;
      }
      if (!this.state.alertProcessingHidden) {
        window.showAlert("Info", "Processing...", "Info");
      }

      try {
        const formData = new FormData();
        formData.append("importFile", this.state.selectedFile);

        const res = await axios({
            method: 'post',
            url: this.state.urlapi + "import",
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
 

        // const response = await fetch(this.state.urlapi + "import", requestOptions);
        // const resultObject = await response.json();

 
        //If created successful
        if (res && res.statusText === "OK") {       
          this.setState({
            importedUser: res.data.data.importUser,
            duplicateUser: res.data.data.duplicateUser
          });
          window.showAlert("", "Save successful!", "");
        }
        else {
          this.doSomethingAfterErrorSaved(res);
        }
      } catch (err) {
        window.showAlert("Error", err.message, "Error");
      }        
  };

  render() {
    const title = this.getTitle();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation navRoute={this.props.navRoute}></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.state.urllist}>{this.state.listtitle}</a>
                    </li>
                    <li>
                      <span>{this.getTitle()}</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>

              <div className="row">
                <div className="col-md-8 col-lg-6">
                  <form
                    className="form-horizontal form-bordered"
                    onSubmit={this.handleImportSubmit}
                    id="form"
                  >
                    <div className="panel">
                      <div className="panel-body">
                        <div
                          class={
                            this.state.data.role === "Student" ? "hide" : ""
                          }
                        >
                          <fieldset className="mb-xl">
                          <div className="form-group">
                              <label
                                className="col-md-3 control-label mt-xs pt-none"
                                htmlFor="status"
                              >
                                Sample file
                              </label>
                              <div className="col-md-9">
                                  <a href="/temp/import_user.xlsx" target="_blank">sample.xlsx</a>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label mt-xs pt-none"
                                htmlFor="status"
                              >
                                Import file
                              </label>
                              <div className="col-md-9">
                                  <input type="file" onChange={this.onFileChange} />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label mt-xs pt-none"
                                htmlFor="status"
                              >
                                Imported User: {this.state.importedUser.length}
                              </label>
                            </div>
                            {this.state.importedUser.length > 0 ? 
                            (
                              <div className="form-group">
                                <label
                                  className="col-md-3 control-label mt-xs pt-none"
                                  htmlFor="status"
                                >
                                {this.state.importedUser.join(", ")}
                                </label>
                              </div>
                            ) : ""}
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label mt-xs pt-none"
                                htmlFor="status"
                              >
                                Duplicate User: {this.state.duplicateUser.length}
                              </label>
                            </div>
                            {this.state.duplicateUser.length > 0 ? 
                            (
                              <div className="form-group">
                                <label
                                  className="col-md-3 control-label mt-xs pt-none"
                                  htmlFor="status"
                                >
                                {this.state.duplicateUser.join(", ")}
                                </label>
                              </div>
                            ) : ""}                           
                          </fieldset>
                        </div>
                      </div>
                      <div className="panel-footer">
                        <div className="text-center">
                          <button type="submit" className="btn btn-success">
                            <i className="fa fa-save fa-lg"></i> Import
                          </button>
                          <a
                            href={
                              params?.role
                                ? `${this.state.urllist}?role=${params?.role}`
                                : this.state.urllist
                            }
                            title="Back to list"
                            className="btn btn-primary"
                          >
                            <i className="fa fa-backward fa-lg"></i> Back
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-12 col-lg-3"></div>
              </div>
              {this.state.alertshow === true ? (
                <Alert
                  message={this.state.alertmsg}
                  type={this.state.alerttype}
                  show={this.state.alertshow}
                ></Alert>
              ) : (
                ""
              )}
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
