import React from "react";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { Helmet } from "react-helmet";
import { getLoggedUser } from "../../context/auth";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state = {
      useExtendBody: false,
      pagination:
        props == undefined ||
          props.pagination == null ||
          props.pagination == undefined
          ? true
          : props.pagination,

      paginationPerPage:
        props == undefined ||
          props.paginationPerPage == null ||
          props.paginationPerPage == undefined
          ? parseInt(GlobalConfig.REACT_APP_PAGE_SIZE)
          : props.paginationPerPage,

      expandableRows: props == undefined ||
        props.expandableRows == null ||
        props.expandableRows == undefined
        ? false
        : props.expandableRows,

      ExpandedComponent: props == undefined ||
        props.expandableRows == null ||
        props.expandableRows == undefined
        ? null
        : props.ExpandedComponent,

      labeladd:
        props == undefined ||
          props.labeladd === null ||
          props.labeladd === undefined ||
          props.labeladd === ""
          ? "Add"
          : props.labeladd,
      urlapi: "",
      urlapidelete: "",
      urllist: "",
      urladd: "",
      params: [],

      screenTitle: "",
      screenSubTitle: "",
      title: "",
      pluraltitle: "",
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      items: [],
      originalItems: [],
      confirmDeleteMessage: "",
      isUseHeading: true,
      showRefresh1: true,
      showRefresh2: false,

      //server paging
      isServerPaging: false,
      loading: false,
      totalRows: 0,
      currentPage: 1,
      perPage: parseInt(GlobalConfig.REACT_APP_PAGE_SIZE),
      paginationPerPage: parseInt(GlobalConfig.REACT_APP_PAGE_SIZE),

      // table
      selectableRows: props?.selectableRows || false,
      selectedRows: [],

      // custom class
      panelClassName: ""
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.doSomethingAfterGetItems = this.doSomethingAfterGetItems.bind(this);
    this.doSomethingAfterDeletedItem =
      this.doSomethingAfterDeletedItem.bind(this);
    this.handleDeleteAfterDeletedOnServer =
      this.handleDeleteAfterDeletedOnServer.bind(this);
  }

  async componentDidMount() {
    // console.log("this.state.isServerPaging=" + this.state.isServerPaging);
    if (this.state.isServerPaging === true) {
      this.getItemsServerPaging(this.state.currentPage);
    } else {
      this.getItems();
    }
  }

  getItems = async () => {
    const response = await fetch(this.state.urlapi);
    const data = await response.json();
    const dataItems = (data.data || []).map((item, index) => ({
      ...item,
      index: index + 1
    }));
    this.setState({ items: dataItems, originalItems: dataItems }, () => {
      this.doSomethingAfterGetItems(data);
    });
  };

  getItemsServerPaging = async (page, size = this.state.perPage, params = this.state.params) => {
    let tempUrl = "";
    if (page && size) {
      tempUrl += `${page}/${size}`;
    }
    const url = [`${this.state.urlapi}${tempUrl}`, (params || []).join('/')].join('/');

    const response = await fetch(url);
    const data = await response.json();
    const dataItems = (data.data || []).map((item, index) => ({
      ...item,
      index: index + 1
    }));
    this.setState({ items: dataItems, originalItems: dataItems }, () => {
      this.doSomethingAfterGetItems(data);
    });
  };

  doSomethingAfterGetItems = (data) => {
    if (this.state.isServerPaging === true) {
      this.setState({ totalRows: data ? data.totalCount : 0, loading: false });
    }
  };

  handleDeleteAfterDeletedOnServer = (removedId) => {
    if (this.state.isServerPaging === true) {
      window.showAlert("Success", "Removed successful!", "");
      //If updated successful
      this.setState(
        {
          currentPage: 1,
        },
        function () {
          this.doSomethingAfterDeletedItem();
          this.getItemsServerPaging(this.state.currentPage);
        }
      );
    } else {
      //If updated successful
      this.setState({
        items: this.state.items.filter(function (item) {
          return item._id !== removedId;
        }),
      });
      this.setState(
        {
          originalItems: this.state.originalItems.filter(function (item) {
            return item._id !== removedId;
          }),
        },
        function () {
          this.doSomethingAfterDeletedItem();
        }
      );
      window.showAlert("Success", "Removed successful!", "");
    }
  };

  doSomethingAfterDeletedItem = () => { };
  doSomethingBeforeDeleteItem = (e) => { };

  handleDelete = (e) => {
    this.doSomethingBeforeDeleteItem(e);
    const removedId = e.currentTarget.dataset.id;
    confirmAlert({
      title: "Confirm to delete",
      message:
        this.state.confirmDeleteMessage == undefined ||
          this.state.confirmDeleteMessage == ""
          ? "Are you sure to delete selected " + this.state.title + "?"
          : this.state.confirmDeleteMessage,
      buttons: [
        {
          className: "btn btn-warning",
          label: "Yes",
          onClick: async () => {
            try {
              const requestOptions = {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  userId: loggedUser._id,
                }),
              };
              const urlapiDelete =
                this.state.urlapidelete == null ||
                  this.state.urlapidelete == undefined ||
                  this.state.urlapidelete == ""
                  ? this.state.urlapi
                  : this.state.urlapidelete;
              const response = await fetch(
                urlapiDelete + removedId,
                requestOptions
              );
              const resultObject = await response.json();
              if (resultObject && resultObject.result === "OK") {
                this.handleDeleteAfterDeletedOnServer(removedId);
              } else {
                //If failed, show error
                window.showAlert("Error", resultObject.message, "error");
              }
            } catch (err) {
              window.showAlert("Error", err.message, "error");
            }
          },
        },
        {
          label: "No",
          className: "btn btn-default",
        },
      ],
    });
  };

  updateStateAfterClosePopup = (data) => {
    if (this.state.isChanged) {
      if (this.state.isEditMode) {
        //update item in array
        this.setState({
          items: this.state.items.map((el) =>
            el._id === data._id ? Object.assign({}, el, data) : el
          ),
        });
        this.setState({
          originalItems: this.state.originalItems.map((el) =>
            el._id === data._id ? Object.assign({}, el, data) : el
          ),
        });
      } else {
        //insert new item in array
        this.setState({
          items: [...this.state.items, data],
        });
        this.setState({
          originalItems: [...this.state.originalItems, data],
        });
      }
    }
  };

  ExpandedComponent = ({ data, ...props }) => {
    return null;
  }

  setColumns = () => {
    return [];
  };

  extendBody = () => {
    return "";
  };

  extendRender = () => {
    return "";
  };

  extendBreadcrumb = () => {
    return "";
  };

  conditionalRowStyles = () => { };

  handlePerRowsChange = async (newPerPage, page) => {
    this.getItemsServerPaging(page, newPerPage);
    this.setState({ perPage: newPerPage });
  };

  handlePageChange = (page) => {
    this.getItemsServerPaging(page);
    this.setState({ currentPage: page });
  };

  extendRenderBeforeTable = () => {
    return "";
  };

  striped = () => {
    return true;
  };

  handleSelectedRowChange = ({ selectedRows }) => {
    this.setState({ selectedRows });
  }

  rowSelectCritera = (row) => {
    return (this.state.selectedRows || []).findIndex(item => item._id === row._id) !== -1;
  };

  defaultTable = () => {
    return this.state.urlapi === null || this.state.urlapi === undefined ? (
      ""
    ) : this.state.isServerPaging === true ? (
      <DataTable
        highlightOnHover
        striped={this.striped()}
        pointerOnHover={false}
        id="emp"
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        conditionalRowStyles={this.conditionalRowStyles()}
        pagination={true}
        paginationPerPage={this.state.paginationPerPage}
        paginationServer={true}
        paginationTotalRows={this.state.totalRows}
        paginationDefaultPage={this.state.currentPage}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
        selectableRows={this.state.selectableRows}
        onSelectedRowsChange={this.handleSelectedRowChange}
        selectableRowSelected={this.rowSelectCritera}
        expandableRows={this.state.expandableRows}
      />
    ) : (
      <DataTable
        highlightOnHover
        striped={this.striped()}
        pointerOnHover={false}
        id="emp"
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        pagination={this.state.pagination}
        paginationPerPage={this.state.paginationPerPage}
        // subHeader={this.state.subHeader}
        // subHeaderComponent={
        //   this.renderHeader()
        // }
        conditionalRowStyles={this.conditionalRowStyles()}
        selectableRows={this.state.selectableRows}
        onSelectedRowsChange={this.handleSelectedRowChange}
        selectableRowSelected={this.rowSelectCritera}
      />
    );
  };

  defaultButtons = () => {
    if (
      this.state.excludeDefaultButtons === true ||
      this.state.urladd === null ||
      this.state.urladd === undefined ||
      this.state.urladd === ""
    ) {
      return "";
    }
    return (
      <div className="text-right">
        <a href={this.state.urladd} className="btn btn-primary">
          <i className="fa fa-plus fa-lg"></i> {this.state.labeladd}
        </a>
      </div>
    );
  };

  extendButtons = () => {
    return "";
  };

  handleSearch = (event) => {
    console.log("Search value is " + event.target.value);
  };

  renderSearch = () => {
    return "";
    // <div style={{ display: 'flex', alignItems: 'center' }}>
    //   <input id="outlined-basic" label="Search" variant="outlined" size="small" style={{ margin: '5px' }} placeholder="Search" className="form-control" maxLength={100} onChange={this.handleSearch} />
    // </div>
  };

  handleRefresh = () => {
    if (this.state.isServerPaging === true) {
      this.setState(
        {
          currentPage: 1,
        },
        function () {
          this.getItemsServerPaging(this.state.currentPage);
        }
      );
    } else {
      this.getItems();
    }
  };

  renderRefresh1 = () => {
    return this.state.showRefresh1 ? (
      <div className="panel-actions">
        <a href="#" title="Refresh" onClick={() => this.handleRefresh()}>
          <i className="fa fa-refresh fa-lg"></i>
        </a>
      </div>
    ) : (
      ""
    );
  };

  renderRefresh2 = () => {
    return "";
  };

  defaultTitle = () => {
    return this.state.pluraltitle !== undefined &&
      this.state.pluraltitle !== null &&
      this.state.pluraltitle !== ""
      ? this.state.pluraltitle
      : this.state.title + "s";
  };

  getTotalItems = () => {
    return this.state.isServerPaging === true
      ? this.state.totalRows
      : this.state.items
        ? this.state.items.length
        : 0;
  };

  setTitleLength = () => {
    return GlobalConfig.REACT_APP_HEADER_TITLE_SIZE;
  };

  truncateTitle = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  render() {
    const { screenTitle } = this.state;
    const title = this.defaultTitle();
    const length = this.getTotalItems();

    return (
      <>
        <Helmet>
          <title>{(screenTitle || title) + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation navRoute={this.props.navRoute}></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2 title={(screenTitle || title)}>
                  {this.truncateTitle((screenTitle || title), this.setTitleLength())}

                  {this.state.screenSubTitle ? (<React.Fragment>
                    <span className="fa fa-angle-right ml-md mr-md"></span>
                    <span className="text-primary">{this.state.screenSubTitle}</span>
                  </React.Fragment>) : null}
                </h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    {this.extendBreadcrumb()}
                    <li>
                      <span title={(screenTitle || title)}>
                        {this.truncateTitle((screenTitle || title), this.setTitleLength())}
                      </span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              {this.state.useExtendBody == null ||
                this.state.useExtendBody == undefined ||
                this.state.useExtendBody != true ? (
                <section className={`panel ${this.state.panelClassName}`}>
                  {this.state.isUseHeading === true ? (
                    <header className="panel-heading">
                      {this.renderRefresh1()}
                      <h2 className="panel-title">
                        {length <= 0
                          ? ""
                          : length == 1
                            ? length + " " + this.state.title + " found"
                            : length +
                            " " +
                            (this.state.pluraltitle === ""
                              ? this.state.title + "s"
                              : this.state.pluraltitle) +
                            " found"}
                      </h2>
                    </header>
                  ) : (
                    ""
                  )}
                  <div className="panel-body">
                    <div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group form-group--action">
                            {this.defaultButtons()}
                            {this.extendButtons()}
                            {this.renderSearch()}
                          </div>
                          {this.renderRefresh2()}
                        </div>
                      </div>
                    </div>
                    {this.extendRenderBeforeTable()}
                    {this.defaultTable()}
                    {this.state.alertshow === true ? (
                      <Alert
                        message={this.state.alertmsg}
                        type={this.state.alerttype}
                        show={this.state.alertshow}
                      ></Alert>
                    ) : (
                      ""
                    )}
                  </div>
                </section>
              ) : (
                this.extendBody()
              )}
            </section>
          </div>
        </section>
        {this.extendRender()}
      </>
    );
  }
}
export default App;
