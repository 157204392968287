import React from "react";
import Select from "react-select";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isSetCheckedFromSource: props.isSetCheckedFromSource,
      controlId: props.controlId,
      urlapilist: props.urlapilist,
      label: props.label,
      value: props.value,
      name: "",
      data: [],
      erroMessage: "",
    };
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.getItems = this.getItems.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);

    //query all classifications in module and render as checkbox list
    this.getItems();
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  getItems = async () => {
    try {
      if (
        this.state.urlapilist == undefined ||
        this.state.urlapilist == null ||
        this.state.urlapilist == ""
      ) {
        return;
      }
      const fetchItem = await fetch(this.state.urlapilist);
      const response = await fetchItem.json();
      if (response && response.result === "OK" && response.data) {
        const parsedItems = [];
        let selectedValue = "";
        if (response.data) {
          if (this.state.isSetCheckedFromSource === true) {
            response.data.map((item) => {
              if (item.checked === true) {
                selectedValue = item._id;
              }
              parsedItems.push({
                _id: item._id,
                name: item.name,
                checked: item.checked,
              });
            });
          } else {
            response.data.map((item) => {
              parsedItems.push({
                _id: item._id,
                name: item.name,
                checked:
                  this.state.value == null ||
                  this.state.value == undefined ||
                  this.state.value.indexOf(item._id) == -1
                    ? false
                    : true,
              });
            });
          }
        }
        this.setState(
          {
            data: parsedItems,
          },
          function callBack() {
            if (this.props.isSetCheckedFromSource === true) {
              //fire event select item
              this.setState({ value: selectedValue });
              this.props.handleChangeSelectControl(
                this.state.controlId,
                selectedValue
              );
            }
          }
        );
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  handleChangeSelect(event) {
    const valueItem = event;

    this.setState({ value: valueItem.value, label: valueItem.label });

    this.props.handleChangeSelectControl(
      this.state.controlId,
      valueItem.value,
      valueItem.value
    );
  }

  setSelected(value) {
    this.setState({ value: value });
  }

  reload(urlapilist) {
    this.setState(
      {
        urlapilist: urlapilist,
      },
      () => {
        this.getItems();
      }
    );
  }

  render() {
    return (
      <>
        <Select
          name={this.state.controlId}
          onChange={this.handleChangeSelect}
          defaultValue={{
            label: this.state.label,
            value: this.state.valueItem,
          }}
          value={this.state.valueItem}
          options={
            this.state.data
              ? this.state.data.map((item) => {
                  return {
                    value: item._id,
                    label: item.name,
                  };
                })
              : ""
          }
        />
      </>
    );
  }
}
export default App;
