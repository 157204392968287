import React from "react";

const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      controlId: props.controlId,
      value: props.value,
      data: [],
    };
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);

    //query all classifications in module and render as checkbox list
    this.getItems();
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  getItems = async () => {
    try {
      const fetchItem = await fetch(this.props.urlapi);
      const response = await fetchItem.json();
      if (response && response.result === "OK" && response.data) {
        const parsedItems = [];
        const attributes = response.data;
        attributes.map((item) => {
          parsedItems.push({
            _id: item._id,
            name: item.name,
            checked:
              this.state.value == null ||
                this.state.value === undefined ||
                this.state.value.indexOf(item) === -1
                ? false
                : true,
          });
        });
        this.setState({
          data: parsedItems,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  setSelected(selectedIds) {
    this.setState({ value: selectedIds });
    if (this.state.data) {
      let updatedData = this.state.data;
      updatedData.forEach((item) => {
        if (
          selectedIds == null ||
          selectedIds === undefined ||
          selectedIds.indexOf(item._id) === -1
        ) {
          item.checked = false;
        } else {
          item.checked = true;
        }
      });
      this.setState({ data: updatedData });
    }
  }

  handleChangeSelect(event) {
    // var id = event.target.name;
    const value = this.props.multiple ?
      Array.from(
        event.target.selectedOptions,
        (option) => option.value
      ) : event.target.value;

    this.setState({ value: value });
    this.props.handleChangeSelectControl(this.state.controlId, value);
  }

  render() {
    return (
      <select
        disabled={this.props.readOnly}
        name={this.state.controlId}
        className="form-control"
        required={this.props.required}
        onChange={this.handleChangeSelect}
        value={this.state.value}
        style={this.props.style}
        multiple={this.props.multiple}
      >
        <option value="">
          {this.props.label ? this.props.label : "Choose one"}
        </option>
        {this.state.data
          ? this.state.data.map((item, key) => {
            return (
              <option value={item._id} key={item._id}>
                {item.name}
              </option>
            );
          })
          : ""}
      </select>
    );
  }
}
export default App;
