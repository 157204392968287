import NumericInput from "react-numeric-input";
import React from "react";
// import PropTypes from "prop-types";

class App extends React.Component {
  //   static propTypes = {
  //     step: PropTypes.instanceOf(NumericInput),
  //   };
  constructor(props) {
    super(props);
    this.state = {
      controlId: props.name,
      labelClass:
        "col-md-" +
        (props.labelClass === null || props.labelClass === undefined
          ? 4
          : props.labelClass) +
        " control-label",
      inputClass:
        "col-md-" +
        (props.inputClass === null || props.inputClass === undefined
          ? 8
          : props.inputClass),
    };
  }

  handleChange = (value) => {
    // console.log(this.state.controlId + " value is " + value);
    this.props.handleChange({
      target: { name: this.state.controlId, value: value },
    });
  };

  handleFocus = (e) => {
    if (this.props.readOnly !== true) {
      if (e != null && e.target != null && e.target.type === "text") {
        e.target.select();
        if ((e.target.value && e.target.value === "0.00") || (e.target.value && e.target.value === "0.0") || (e.target.value && e.target.value === "0")) {
          e.target.value = "";
        }
      }
    }
  };

  render() {
    return (
      this.props.label && this.props.label !== `` ?
        <div className="form-group">
          {this.props.labelrequired === undefined ? (
            <label className={this.state.labelClass} htmlFor={this.props.name}>
              {this.props.label}
            </label>
          ) : (
            <label className={this.state.labelClass} htmlFor={this.props.name}>
              {this.props.label}{" "}
              <span className="required">{this.props.labelrequired}</span>
            </label>
          )}
          {this.props.unit === undefined || this.props.unit == null ? (
            <div className={this.state.inputClass}>
              <NumericInput
                inputMode="numeric"
                strict
                title={"Max is " + this.props.max}
                className="form-control"
                noStyle={this.props.noStyle || this.props.readOnly}
                readOnly={this.props.readOnly}
                min={this.props.min}
                max={this.props.max}
                precision={this.props.precision}
                step={this.props.step}
                name={this.props.name}
                value={this.props.value}
                onChange={this.handleChange}
                required={this.props.required}
                onFocus={this.handleFocus}
                style={{ zIndex: 1 }}
              />
              {this.props.note === undefined ||
                this.props.note == null ||
                this.props.note === "" ? (
                ""
              ) : (
                <label>{this.props.note}</label>
              )}
            </div>
          ) : (
            <div className={this.state.inputClass}>
              <div className="input-group">
                <NumericInput
                  inputMode="numeric"
                  strict
                  title={"Max is " + this.props.max}
                  className="form-control"
                  noStyle
                  readOnly={this.props.readOnly}
                  min={this.props.min}
                  max={this.props.max}
                  precision={this.props.precision}
                  step={this.props.step}
                  name={this.props.name}
                  value={this.props.value}
                  onChange={this.handleChange}
                  required={this.props.required}
                  onFocus={this.handleFocus}
                  style={{ zIndex: 1 }}
                />
                <span className="input-group-addon">{this.props.unit}</span>
              </div>
            </div>
          )}
        </div> :
        <NumericInput
          inputMode="numeric"
          strict
          title={"Max is " + this.props.max}
          className="form-control"
          noStyle
          readOnly={this.props.readOnly}
          min={this.props.min}
          max={this.props.max}
          precision={this.props.precision}
          step={this.props.step}
          name={this.props.name}
          value={this.props.value}
          onChange={this.handleChange}
          required={this.props.required}
          onFocus={this.handleFocus}
          style={{ zIndex: 1 }}
        />
    );
  }
}
export default App;
