import React from "react";
import DateFormat from "../Utilities/DateFormat";
import ListComponent from "../Base/ListComponent";

const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    this.state.parentId = props.match.params.id;
    this.state.urlapi = GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL;
    this.state.urllist =
      process.env.REACT_APP_URL_CLASSIFICATION_LIST +
      "/classifications/" +
      props.match.params.id;
    this.state.urledit =
      process.env.REACT_APP_URL_CLASSIFICATION_LIST + "/item-edit/";
    this.state.urladd =
      process.env.REACT_APP_URL_CLASSIFICATION_LIST +
      "/item-add/" +
      props.match.params.id;
    this.state.title = "Classification Item";
    this.state.pluraltitle = "Classification Items";
  }

  getItems = async () => {
    const response = await fetch(
      this.state.urlapi + "/parent/" + this.state.parentId
    );
    const data = await response.json();
    this.setState({ items: data.data });
  };

  setColumns = () => {
    return [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        cell: (row) => (
          <a href={this.state.urledit + row._id} title={row.name}>
            {row.name}
          </a>
        ),
      },
      {
        name: "Modified Date",
        selector: "modifiedDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return row.modifiedDate ? (
            <DateFormat date={row.modifiedDate}></DateFormat>
          ) : (
            ""
          );
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return <DateFormat date={row.createdDate}></DateFormat>;
        },
      },
      {
        name: "",
        cell: (row) => (
          <div>
            <a
              href={this.state.urledit + row._id}
              className="on-default edit-row"
            >
              <i className="fa fa-pencil-square-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            <a
              title="Click here to remove"
              href="#"
              className="on-default remove-row"
              data-id={row._id}
              onClick={this.handleDelete.bind(this)}
            >
              <i className="fa fa-trash-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </div>
        ),
      },
    ];
  };

  extendBreadcrumb = () => {
    return (
      <li>
        <a href={process.env.REACT_APP_URL_CLASSIFICATION_LIST} title="Back to list">
          Classifications
        </a>
      </li>
    );
  };

  extendButtons = () => {
    return (
      <a
        href={process.env.REACT_APP_URL_CLASSIFICATION_LIST}
        title="Back Classifications"
        className="btn btn-primary"
      >
        <i className="fa fa-backward fa-lg"></i> Back Classifications
      </a>
    );
  };
}
export default App;
