import React from "react";
import EditComponent from "../Base/EditComponent";
import { getLoggedUser } from "../../context/auth";
// import FileBase64 from "react-file-base64";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { Helmet } from "react-helmet";
import CommonUtilities from "../Utilities/Common";
import SelectConfig from "../Base/SelectConfig";
import Switch from "react-switch";
import DateFormat from "../Utilities/DateFormat";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends EditComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state = {
      listtitle: "Manage Users",
      urllist: process.env.REACT_APP_URL_USER_LIST,
      urlapi: GlobalConfig.REACT_APP_API_USER_URL,
      data: {
        _id: props.match.params.id,
        role: "",
        name: "",
        email: "",
        userId: "",
        status: "",
        matriculationNumber: "",
        classId: "",
        password: "",
        avatar: "",
        avatarExtension: "",
        createdUserId: "",
        createdDate: null,
        modifiedUserId: loggedUser._id,
        modifiedDate: new Date(),
      },
      errors: {
        email: "",
      },
    };
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
  }

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      data: Object.assign({}, this.state.data, {
        [controlId]: value,
      }),
    });
  };

  onChangeStatus = (toggle) => {
    const status = toggle ? "Active" : "Inactive";
    this.setState({
      data: { ...this.state.data, status: status },
    });
  };

  doSomethingAfterGetItemById = () => {
    this.role.setSelected(this.state.data.role);
    // this.classId.setSelected(this.state.data.classId);
  };

  checkSomethingBeforeSave = () => {
    const isValidEmail = CommonUtilities.isValidEmail(this.state.data.email);
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        email: isValidEmail,
      }),
    });
    if (isValidEmail && isValidEmail !== "") {
      this.email.focus();
      return false;
    } else {
      return true;
    }
  };

  getTitle = () => {
    return this.state.data.name;
  };

  render() {
    const title = "Edit - " + this.getTitle();
    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation navRoute={this.props.navRoute}></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.state.urllist}>{this.state.listtitle}</a>
                    </li>
                    <li>
                      <span>{this.getTitle()}</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>

              <div className="row">
                <div className="col-md-8 col-lg-6">
                  <form
                    className="form-horizontal form-bordered"
                    onSubmit={this.handleSubmit}
                    id="form"
                  >
                    <div className="panel">
                      <div className="panel-body">
                        <fieldset>
                          <div className="form-group row">
                            <label className="col-md-3 control-label">
                              User ID <span className="required">(*)</span>
                            </label>
                            <div className="col-md-9">
                              <input
                                maxLength="150"
                                type="text"
                                name="userId"
                                className="form-control"
                                placeholder="Enter User ID"
                                required
                                value={this.state.data.userId}
                                onChange={this.handleChange}
                                ref={(input) => {
                                  this.userIdInput = input;
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="col-md-3 control-label"
                              htmlFor="name"
                            >
                              Full Name <span className="required">(*)</span>
                            </label>
                            <div className="col-md-9">
                              <input
                                maxLength="150"
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder=""
                                required
                                value={this.state.data.name}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="col-md-3 control-label"
                              htmlFor="email"
                            >
                              Email
                            </label>
                            <div className="col-md-9">
                              <input
                                maxLength="50"
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder=""
                                value={this.state.data.email}
                                onChange={this.handleChange}
                                ref={(input) => {
                                  this.email = input;
                                }}
                              />
                              {this.state.errors.email &&
                              this.state.errors.email !== "" ? (
                                <label style={{ color: "#d2322d" }}>
                                  {this.state.errors.email}
                                </label>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-md-3 control-label">
                              Role Type <span className="required">(*)</span>
                            </label>
                            <div className="col-md-9">
                              <SelectConfig
                                configType={
                                  process.env.REACT_APP_CONFIG_NAME_USER_ROLE
                                }
                                required={true}
                                onRef={(ref) => (this.role = ref)}
                                name="role"
                                controlId="role"
                                selectedIds={this.state.data.role}
                                handleChangeSelectControl={
                                  this.handleChangeSelectControl
                                }
                                exceptData={["Super Administrator"]}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 control-label import-csv-label">
                              Active
                            </label>
                            <div className="col-md-9 flex-box align-items-center">
                              <Switch
                                onChange={this.onChangeStatus}
                                checked={
                                  this.state.data
                                    ? this.state.data.status === "Active"
                                      ? true
                                      : false
                                    : false
                                }
                                className="react-switch"
                              />
                            </div>
                          </div>
                          {/* <div className="form-group">
                            <label
                              className="col-md-3 control-label"
                              htmlFor="matriculationNumber"
                            >
                              Matriculation Number
                            </label>
                            <div className="col-md-9">
                              <input
                                className="form-control"
                                name="matriculationNumber"
                                value={this.state.data.matriculationNumber}
                                onChange={this.handleChange}
                                ref={(input) => {
                                  this.matriculationNumber = input;
                                }}
                              />
                              {this.state.errors.matriculationNumber && (
                                <label style={{ color: "#d2322d" }}>
                                  {this.state.errors.matriculationNumber}
                                </label>
                              )}
                            </div>
                          </div> */}
                          {/* <div className="form-group">
                            <label
                              className="col-md-3 control-label"
                              htmlFor="matriculationNumber"
                            >
                              Class
                            </label>
                            <div className="col-md-9">
                              <AsyncSelect
                                urlapi={process.env.REACT_APP_API_CLASS_URL}
                                onRef={(ref) => (this.classId = ref)}
                                name="classId"
                                controlId="classId"
                                value={this.state.data.classId}
                                selectedIds={this.state.data.classId}
                                handleChangeSelectControl={
                                  this.handleChangeSelectControl
                                }
                                multiple={true}
                              />
                            </div>
                          </div> */}
                        </fieldset>
                        {/* <div
                          className={
                            this.state.data.role === "Student" ? "hide" : ""
                          }
                        >
                          <hr className="dotted tall"></hr>
                          <h4 className="mb-xlg" style={{ fontSize: "18px" }}>
                            Access Portal
                          </h4>
                          <fieldset className="mb-xl">
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label mt-xs pt-none"
                                htmlFor="status"
                              >
                                Status
                              </label>
                              <div className="col-md-9">
                                <select
                                  name="status"
                                  className="form-control"
                                  required
                                  value={this.state.data.status}
                                  onChange={this.handleChange}
                                >
                                  <option value="Active">Active</option>
                                  <option value="Inactive">Inactive</option>
                                </select>
                              </div>
                            </div>
                          </fieldset>
                        </div> */}
                      </div>
                      <div className="panel-footer">
                        <div className="text-center">
                          <button type="submit" className="btn btn-success">
                            <i className="fa fa-save fa-lg"></i> Save
                          </button>
                          <a
                            href={this.state.urllist}
                            title="Back to list"
                            className="btn btn-primary"
                          >
                            <i className="fa fa-backward fa-lg"></i> Back
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-12 col-lg-3"></div>
              </div>
              {this.state.alertshow === true ? (
                <Alert
                  message={this.state.alertmsg}
                  type={this.state.alerttype}
                  show={this.state.alertshow}
                ></Alert>
              ) : (
                ""
              )}
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
