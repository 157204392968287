import React from "react";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { getLoggedUser } from "../../context/auth";
import { Helmet } from "react-helmet";

let loggedUser = {};
class App extends React.Component {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state = {
      useExtendBody: false,
      isShowSaveSuccessfulMessage:
        props == undefined ||
          props.isShowSaveSuccessfulMessage == null ||
          props.isShowSaveSuccessfulMessage == undefined
          ? true
          : props.isShowSaveSuccessfulMessage,
      title: "",
      listtitle: "",
      urllist: "",
      urlapi: "",
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      imageFieldName: "",
      imageFieldExtension: "",
      imageMaxSize: 0,
      data: {},
      alertProcessingHidden: false,
      
      // custom class
      panelClassName: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChange4Image = this.handleChange4Image.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  getTitle = () => {
    return "";
  };

  handleChange(event, handleValidate) {
    if (handleValidate) handleValidate.handleChange(event);
    var id = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({
      data: Object.assign({}, this.state.data, {
        [id]: value,
      }),
    });
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error on getBase64: ", error);
    };
  }

  handleChange4Image(event) {
    var id = event.target.name;
    // event.target.files[0];
    var files = event.target.files[0];
    if (files) {
      if (files.type == "image/jpeg" || files.type == "image/png") {
        // if (parseInt(files.size.split(" ")[0]) > this.state.imageMaxSize) {
        //   window.showAlert(
        //     "Error",
        //     "Does not allow image over " + this.state.imageMaxSize + " KB. Please upload another.",
        //     "error"
        //   );
        //   return;
        // }
        let filesBase64 = "";
        this.getBase64(files, (result) => {
          filesBase64 = result;
          const arrTemp = files.name.split(".");
          const fileExtension = arrTemp[arrTemp.length - 1];
          this.setState({
            data: Object.assign({}, this.state.data, {
              [this.state.imageFieldName]: filesBase64,
              [this.state.imageFieldExtension]: fileExtension,
            }),
          });
        });
      } else {
        window.showAlert("Error", "Please choose PNG or JPG", "error");
      }
    } else {
      this.setState({
        data: Object.assign({}, this.state.data, {
          [this.state.imageFieldName]: "",
        }),
      });
    }
  }

  handleImageChange(files) {
    //files
    //   {
    //     "name": "logo_react.jpg",
    //     "type": "image/jpeg",
    //     "size": "57 kB",
    //     "base64": "data:image/jpeg;base64,/9j/4AAQSkZJRg...",
    //     "file": {}
    //   }
    // ]
    if (files) {
      if (files.type == "image/jpeg" || files.type == "image/png") {
        // if (parseInt(files.size.split(" ")[0]) > this.state.imageMaxSize) {
        //   window.showAlert(
        //     "Error",
        //     "Does not allow image over " + this.state.imageMaxSize + " KB. Please upload another.",
        //     "error"
        //   );
        //   return;
        // }
        const arrTemp = files.name.split(".");
        const fileExtension = arrTemp[arrTemp.length - 1];
        this.setState({
          data: Object.assign({}, this.state.data, {
            [this.state.imageFieldName]: files.base64,
            [this.state.imageFieldExtension]: fileExtension,
          }),
        });
      } else {
        window.showAlert("Error", "Please choose PNG or JPG", "error");
      }
    } else {
      this.setState({
        data: Object.assign({}, this.state.data, {
          [this.state.imageFieldName]: "",
        }),
      });
    }
  }

  handResetForm = () => { };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.handleSave();
  };

  handleSave = async () => {
    if (this.checkSomethingBeforeSave() === false) {
      return;
    }
    if (!this.state.alertProcessingHidden) {
      window.showAlert("Info", "Processing...", "Info");
    }
    this.setState(
      {
        data: Object.assign({}, this.state.data, {
          ["createdUserId"]: loggedUser._id,
        }),
      },
      async function callBack() {
        try {
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.state.data),
          };

          const response = await fetch(this.state.urlapi, requestOptions);
          const resultObject = await response.json();

          //If created successful
          if (resultObject && resultObject.result === "OK") {
            //reset form
            this.handResetForm();
            this.doSomethingAfterSaved(resultObject.data);
            if (
              this.state.isShowSaveSuccessfulMessage == true ||
              this.state.isShowSaveSuccessfulMessage == undefined ||
              this.state.isShowSaveSuccessfulMessage == null
            ) {
              window.showAlert("", "Save successful!", "");
            }
          }
          //If failed, show error
          else {
            this.doSomethingAfterErrorSaved(resultObject);
          }
        } catch (err) {
          window.showAlert("Error", err.message, "Error");
        }
      }
    );
  };

  checkSomethingBeforeSave = () => {
    return true;
  };

  extendBody = () => {
    return "";
  };

  render() {
    const title = "Create new " + this.state.title;
    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation navRoute={this.props.navRoute}></Navigation>
            <section role="main" className="content-body">
              <header className="page-header">
                <h2>Create new {this.state.title}</h2>
                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.state.urllist}>{this.state.listtitle}</a>
                    </li>
                    {this.extendBreadcrumb()}
                    <li>
                      <span>{title}</span>
                    </li>
                  </ol>
                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              {this.state.useExtendBody == null ||
                this.state.useExtendBody == undefined ||
                this.state.useExtendBody != true ? (
                <div className="row">
                  <div className="col-md-12">
                    <form
                      onSubmit={this.handleSubmit}
                      id="form"
                      className="form-horizontal"
                    >
                      <section className={`panel ${this.state.panelClassName}`}>
                        <header className="panel-heading">
                          <h2 className="panel-title">
                            {this.state.sectionTitle == undefined
                              ? "Basic Information"
                              : this.state.sectionTitle}
                          </h2>
                        </header>
                        {this.renderForm()}
                        {this.renderFooter()}
                      </section>
                    </form>
                    {this.state.alertshow === true ? (
                      <Alert
                        message={this.state.alertmsg}
                        type={this.state.alerttype}
                        show={this.state.alertshow}
                      ></Alert>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                this.extendBody()
              )}
            </section>
          </div>
        </section>
      </>
    );
  }

  renderForm = () => {
    return "";
  };

  renderFooter = () => {
    return (
      <footer className="panel-footer">
        <div className="text-center">
          <button
            type="submit"
            className="btn btn-success"
            style={{ marginLeft: "0px" }}
          >
            <i className="fa fa-save fa-lg"></i> Save
          </button>
          <a
            href={this.state.urllist}
            title="Back to list"
            className="btn btn-primary"
          >
            <i className="fa fa-backward fa-lg"></i> Back
          </a>
        </div>
      </footer>
    );
  };

  extendBreadcrumb = () => {
    return "";
  };

  extendButtons = () => {
    return "";
  };

  setTitle = () => {
    return this.state.data.name;
  };

  doSomethingAfterSaved = (data) => { };

  doSomethingAfterErrorSaved(res) {
    window.showAlert("Error", res.message, "Error");
  }
}
export default App;
