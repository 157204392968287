import React from "react";
import AddComponent from "../Base/AddComponent";
import { Helmet } from "react-helmet";

class App extends AddComponent {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div
        style={{
          backgroundImage:
            "url(/assets/images/background.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Helmet>
          <title>{"Terms of Use | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section
          className="body-sign"
          style={{ color: "white", paddingBottom: "50px" }}
        >
        </section>
      </div>
    );
  }
}
export default App;
