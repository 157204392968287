import React, { useState } from "react";
import config from "./config"; //do not remove

import AdministratorList from "./Components/Administrator/route";
import AdministratorAdd from "./Components/Administrator/routeadd";
import AdministratorEdit from "./Components/Administrator/routeedit";

import MailTemplateList from "./Components/MailTemplate/route";
import MailTemplateAdd from "./Components/MailTemplate/routeadd";
import MailTemplateEdit from "./Components/MailTemplate/routeedit";

import AuditLogList from "./Components/AuditLog/route";
import AuditLogView from "./Components/AuditLog/routeview";

import ConfigList from "./Components/Config/route";
import ConfigAdd from "./Components/Config/routeadd";
import ConfigEdit from "./Components/Config/routeedit";

import Page404 from "./Components/Pages/PageNotFound";

import UserList from "./Components/User/route";
import UserAdd from "./Components/User/routeadd";
import UserEdit from "./Components/User/routeedit";
import UserImport from "./Components/User/routeimport";
import UserGameRecord from "./Components/User/routegamerecord";

import ClassificationList from "./Components/Classification/route";
import ClassificationAdd from "./Components/Classification/routeadd";
import ClassificationEdit from "./Components/Classification/routeedit";

import ClassificationItemList from "./Components/ClassificationItem/route";
import ClassificationItemAdd from "./Components/ClassificationItem/routeadd";
import ClassificationItemEdit from "./Components/ClassificationItem/routeedit";

import Home from "./Components/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import { AuthContext } from "./context/auth";
import Login from "./Components/Pages/Login";
import Activation from "./Components/Pages/Activation";
import TermsOfUse from "./Components/Pages/TermsOfUse";
import RecoverPassword from "./Components/Pages/RecoverPassword";
import Profile from "./Components/Pages/Profile";
import ResetPassword from "./Components/Pages/ResetPassword";
import ChangePassword from "./Components/Pages/ChangePassword";

function App(props) {
  const tokens = localStorage.getItem("tokens");
  let existingTokens = null;
  if (
    tokens !== null &&
    tokens !== undefined &&
    tokens.toString() !== "undefined" &&
    tokens.toString() !== ""
  ) {
    existingTokens = JSON.parse(tokens);
  }
  const [authTokens, setAuthTokens] = useState(existingTokens);

  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  };

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Router>
        <div>
          <Switch>
            <Route
              path={process.env.REACT_APP_URL_LOGIN}
              exact
              component={Login}
            />
            <Route
              path={process.env.REACT_APP_URL_ACTIVATION}
              exact
              component={Activation}
            />
            <Route
              path={process.env.REACT_APP_URL_TERMS_OF_USE}
              exact
              component={TermsOfUse}
            />
            <Route
              path={process.env.REACT_APP_URL_RECOVER_PASSWORD}
              exact
              component={RecoverPassword}
            />
            <Route
              path={process.env.REACT_APP_URL_RESET_PASSWORD}
              exact
              component={ResetPassword}
            />

            <PrivateRoute
              path="/"
              exact
              component={Home}
              navRoute={["dashboard", "summary"]}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_SUPPER_USER_LIST}
              exact
              component={AdministratorList}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_SUPPER_USER_LIST + "-add"}
              exact
              component={AdministratorAdd}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_SUPPER_USER_LIST + "/:id"}
              exact
              component={AdministratorEdit}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_USER_LIST}
              exact
              component={UserList}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_USER_LIST + "-add"}
              exact
              component={UserAdd}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_USER_LIST + "-import"}
              exact
              component={UserImport}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_USER_LIST + "/:id"}
              exact
              component={UserEdit}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_GAME_RECORD_LIST + "/:userId"}
              exact
              component={UserGameRecord}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_MAIL_TEMPLATE_LIST + "/:id"}
              exact
              component={MailTemplateEdit}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_MAIL_TEMPLATE_LIST}
              exact
              component={MailTemplateList}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_MAIL_TEMPLATE_LIST + "-add"}
              exact
              component={MailTemplateAdd}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_SYSTEMCONFIG_LIST}
              exact
              component={ConfigList}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_SYSTEMCONFIG_LIST + "-add"}
              exact
              component={ConfigAdd}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_SYSTEMCONFIG_LIST + "/:id"}
              exact
              component={ConfigEdit}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_MY_PROFILE}
              exact
              component={Profile}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST}
              exact
              component={AuditLogList}
              navRoute={["grower-tools", "revision-history"]}
            ></PrivateRoute>
            <PrivateRoute
              path={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/:id"
              }
              exact
              component={AuditLogList}
              navRoute={["grower-tools", "revision-history"]}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST + "/:id"}
              exact
              component={AuditLogView}
              navRoute={["grower-tools", "revision-history"]}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_CLASSIFICATION_LIST + "/:id"}
              exact
              component={ClassificationEdit}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_CLASSIFICATION_LIST}
              exact
              component={ClassificationList}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_CLASSIFICATION_ADD}
              exact
              component={ClassificationAdd}
            ></PrivateRoute>

            <PrivateRoute
              path={
                process.env.REACT_APP_URL_CLASSIFICATION_LIST +
                "/classifications/:id"
              }
              exact
              component={ClassificationItemList}
            ></PrivateRoute>

            <PrivateRoute
              path={
                process.env.REACT_APP_URL_CLASSIFICATION_LIST + "/item-add/:id"
              }
              exact
              component={ClassificationItemAdd}
            ></PrivateRoute>

            <PrivateRoute
              path={
                process.env.REACT_APP_URL_CLASSIFICATION_LIST + "/item-edit/:id"
              }
              exact
              component={ClassificationItemEdit}
            ></PrivateRoute>

            <Route
              path={process.env.REACT_APP_URL_CHANGE_PASSWORD}
              exact
              component={ChangePassword}
            />

            <Route
              path={process.env.REACT_APP_URL_PAGE_NOT_FOUND}
              exact
              component={Page404}
            ></Route>

            <Route component={Page404}></Route>
          </Switch>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
