import React from "react";
import ListComponent from "../Base/ListComponent";
import NumericInput from "../FormControl/NumericInput";
import { Button, Modal } from "react-bootstrap";
import MultiSelect from "../FormControl/MultiSelect";
import DateFormat from "../Utilities/DateFormat";
import ReactJson from "react-json-view";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import CommonUtilities from "../Utilities/Common";
const FileSaver = require("file-saver");
const XLSX = require("xlsx");

const GlobalConfig = new window.globalConfig();
export default class App extends ListComponent {
  constructor(props) {
    super();

    this.state.screenTitle = "Game Records";
    this.state.title = "Game Record";
    this.state.urlapi = `${process.env.REACT_APP_API_GAMERECORD_URL}${props.match.params.userId}`;
    this.state.gameRecords = [];
    this.state.selectedGameRecord = {};
    this.state.showGameRecord = false;
  }

  showGameRecordPopup = (record) => {
    this.setState({
      selectedGameRecord: record,
      showGameRecord: true,
    });
  };

  hideGameRecordPopup = () => {
    this.setState({
      selectedGameRecord: {},
      showGameRecord: false,
    });
  };

  export = async () => {
    const userId = this.props.match.params.userId;
    fetch(`${GlobalConfig.REACT_APP_API_USER_URL}information/${userId}`)
      .then((res) => res.json())
      .then((responseData) => {
        let user = {};
        if (responseData.result === "OK") {
          user = responseData.data;
          delete user._id;
        } else {
          window.showAlert("ERROR", responseData.message, "error");
        }
        this.exportExcel(user);
      })
      .catch((getUserException) => {
        console.log(getUserException.message);
        this.exportExcel({});
      });
  };

  exportExcel = (user) => {
    try {
      const userExists = JSON.stringify(user) !== "{}";
      if (!userExists) {
        window.showAlert(
          "ERROR",
          "Could not export. User may not exist longer.",
          "error"
        );
        return;
      }
      const exportData = this.getExportData([...this.state.items], user);
      const header = [
        [
          "UserID",
          "Name",
          "Status",
          "Scene",
          "Scene Title",
          "Level",
          "Start Time",
          "End Time",
          "Spent Time",
          "Saved Date",
        ].concat(
          CommonUtilities.getQuestionAndAnswerColumns([...this.state.items])
        ),
      ];
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const ws = XLSX.utils.json_to_sheet(exportData);
      XLSX.utils.sheet_add_aoa(ws, header);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], { type: fileType });
      const exportDate = moment(new Date()).format("YYYYMMDD");
      FileSaver.saveAs(data, `${user["User ID"]}_${exportDate}.xlsx`);
    } catch (exception) {
      console.log(exception.message);
    }
  };

  getExportData = (records, user) => {
    const exportData = records.map((record, index) => {
      const spentTime =
        Date.parse(record.endTime) - Date.parse(record.startTime);
      const row = {
        UserID: index === 0 ? user["User ID"] : "",
        Name: index === 0 ? user.Name : "",
        Status: index === 0 ? user.Status : "",
        Scene: record.scene,
        "Scene Title": record.sceneTitle,
        Level: record.level,
        "Start Time": record.startTime,
        "End Time": record.endTime,
        "Spent Time": new Date(spentTime).toISOString().slice(11, 16),
        "Saved Date": moment(record.createdDate).format("MM-DD-YYYY hh:mm A"),
      };
      CommonUtilities.appendQuestionAndAnswerProperties(
        row,
        record.listOfQuestionsAndAnswers
      );
      return row;
    });
    return exportData;
  };

  defaultButtons = () => {
    return (
      <div className="flex-box space-between">
        <a href={process.env.REACT_APP_URL_USER_LIST}>
          <Button style={{ margin: 0 }} variant="primary">
            <i class="fa fa-arrow-left" aria-hidden="true"></i> Back
          </Button>
        </a>
        <Button style={{ margin: 0 }} variant="warning" onClick={this.export}>
          <i className="fa fa-file-excel-o"></i> Export CSV
        </Button>
      </div>
    );
  };

  extendRender = () => {
    return (
      <>
        <Modal
          show={this.state.showGameRecord}
          onHide={this.hideGameRecordPopup}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
          className="game-record-popup"
        >
          <Modal.Header>
            <Modal.Title>
              <p style={{ fontWeight: 600 }}>Game Record</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="game-record-popup-body">
            <div className="form-group game-record-field">
              <NumericInput
                min={0}
                max={99999}
                label={"Scene"}
                readOnly={true}
                labelClass={3}
                inputClass={9}
                precision={0}
                step={1}
                name="scene"
                value={this.state.selectedGameRecord.scene}
                handleChange={() => {}}
              ></NumericInput>
            </div>
            <div className="form-group game-record-field">
              <label className="col-md-3 control-label">Scene Title</label>
              <div className="col-md-9">
                <input
                  type="text"
                  name="sceneTitle"
                  readOnly
                  className="form-control"
                  maxLength={200}
                  value={this.state.selectedGameRecord.sceneTitle}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-3 control-label">Level</label>
              <div className="col-md-9">
                <MultiSelect
                  placeholder="Select a Level"
                  closeMenuOnSelect={true}
                  isMulti={false}
                  onChange={(newLevel) => {
                    console.log(`Level changed to ${newLevel.value}`);
                  }}
                  value={this.state.selectedGameRecord.level}
                  defaultOptions={[
                    {
                      label: "easy",
                      value: "easy",
                    },
                    {
                      label: "advance",
                      value: "advance",
                    },
                  ]}
                  isOptionDisabled={true}
                ></MultiSelect>
              </div>
            </div>
            <div className="form-group game-record-field">
              <label className="col-md-3 control-label">Start Time</label>
              <div className="col-md-9">
                <input
                  type="text"
                  name="startTime"
                  readOnly
                  className="form-control"
                  maxLength={20}
                  value={this.state.selectedGameRecord.startTime}
                />
              </div>
            </div>
            <div className="form-group game-record-field">
              <label className="col-md-3 control-label">End Time</label>
              <div className="col-md-9">
                <input
                  type="text"
                  name="endTime"
                  readOnly
                  className="form-control"
                  maxLength={20}
                  value={this.state.selectedGameRecord.endTime}
                />
              </div>
            </div>
            <div className="form-group game-record-field">
              <NumericInput
                min={0}
                max={99999}
                readOnly={true}
                label={"Spent Time"}
                labelClass={3}
                inputClass={9}
                precision={0}
                step={1}
                name="spentTime"
                value={this.state.selectedGameRecord.spentTime}
                handleChange={() => {}}
              ></NumericInput>
            </div>
            <div className="form-group game-record-field">
              <label className="col-md-3 control-label">
                Questions & Answers
              </label>
              <div className="col-md-9">
                {this.state.selectedGameRecord.listOfQuestionsAndAnswers ? (
                  <ReactJson
                    style={{ wordBreak: "break-all" }}
                    src={JSON.parse(
                      this.state.selectedGameRecord.listOfQuestionsAndAnswers
                    )}
                    theme="apathy"
                    name={false}
                    enableClipboard={true}
                    displayObjectSize={false}
                    displayDataTypes={false}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="flex-box-content-center game-record-popup-footer">
            <Button variant="warning" onClick={this.hideGameRecordPopup}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  onClickDeleteGameRecord = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-popup-custom">
            <div className="confirm-popup-custom-title">Confirm To Delete</div>
            <p className="confirm-popup-custom-message">
              Are you sure you want to delete this record?
            </p>
            <div className="confirm-popup-custom-footer">
              <Button
                variant="primary"
                onClick={() => {
                  onClose();
                  this.deleteGameRecord(id);
                }}
              >
                <i className="fa fa-check" aria-hidden="true"></i> Yes
              </Button>
              <Button variant="warning" button-type="cancel" onClick={onClose}>
                <i className="fa fa-times" aria-hidden="true"></i> Cancel
              </Button>
            </div>
          </div>
        );
      },
    });
  };

  deleteGameRecord = (id) => {
    fetch(`${process.env.REACT_APP_API_GAMERECORD_URL}${id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((responseData) => {
        if (responseData.result === "OK") {
          const items = [...this.state.items].filter((item) => item._id !== id);
          this.setState({
            items: items,
          });
          window.showAlert(
            "Congratulation",
            "Game record has been deleted.",
            ""
          );
        } else {
          window.showAlert(responseData.result, responseData.message, "error");
        }
      })
      .catch((exception) => {
        window.showAlert("ERROR", exception.message, "error");
      });
  };

  setColumns = () => {
    return [
      {
        name: "Scene",
        selector: "scene",
        width: "100px",
        cell: (record) => {
          return record?.scene;
        },
      },
      {
        name: "Scene Title",
        selector: "sceneTitle",
        cell: (record) => {
          return record?.sceneTitle;
        },
      },
      {
        name: "Level",
        selector: "level",
        cell: (record) => {
          return record?.level;
        },
      },
      {
        name: "Type",
        selector: "type",
        cell: (record) => {
          return record?.type;
        },
      },
      {
        name: "Start Time",
        selector: "startTime",
        width: "200px",
        cell: (record) => {
          return record?.startTime;
        },
      },
      {
        name: "End Time",
        selector: "endTime",
        width: "200px",
        cell: (record) => {
          return record?.endTime;
        },
      },
      {
        name: "Spent Time",
        selector: "spentTime",
        cell: (record) => {
          try {
            const spentTime =
              Date.parse(record.endTime) - Date.parse(record.startTime);
            return new Date(spentTime).toISOString().slice(11, 16);
          } catch (exception) {
            return NaN;
          }
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        cell: (record) => {
          return <DateFormat date={record?.createdDate}></DateFormat>;
        },
      },
      {
        name: "",
        cell: (record) => {
          return (
            <div className="flex-box">
              <div
                title="Click here to view detail"
                className="game-record-action"
                onClick={() => this.showGameRecordPopup(record)}
              >
                View
              </div>
              <div
                title="Click here to remove"
                className="game-record-action game-record-delete-button"
                onClick={() => {
                  this.onClickDeleteGameRecord(record._id);
                }}
              >
                <i class="fa fa-trash-o fa-lg" aria-hidden="true"></i>
              </div>
            </div>
          );
        },
      },
    ];
  };
}
