window.environmentConfig = {
  REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
};

exports.environmentConfig = window.environmentConfig;

window.globalConfig = function () {
  this.REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
  this.REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  this.REACT_APP_API_SYSTEMCONFIG_URL = `${this.REACT_APP_API_URL}/apiconfig/`;
  this.REACT_APP_API_MAIL_URL = `${this.REACT_APP_API_URL}/apimail/`;
  this.REACT_APP_API_AUTH_URL = `${this.REACT_APP_API_URL}/apiauth`;
  this.REACT_APP_API_CLASSIFICATION_URL = `${this.REACT_APP_API_URL}/apiclassification/`;
  this.REACT_APP_API_CLASSIFICATION_ITEM_URL = `${this.REACT_APP_API_URL}/apiclassificationitem/`;
  this.REACT_APP_API_USER_URL = `${this.REACT_APP_API_URL}/apiuser/`;
  this.REACT_APP_API_MAIL_TEMPLATE_URL = `${this.REACT_APP_API_URL}/apimailtemplate/`;
  this.REACT_APP_API_DASHBOARD_URL = `${this.REACT_APP_API_URL}/apidashboard/`;
  this.REACT_APP_API_AUDIT_URL = `${this.REACT_APP_API_URL}/apiaudit/`;

  this.REACT_APP_LOGIN_EXPIRY = 720; //in minute (12 hours)
  this.REACT_APP_PAGE_SIZE = 20;
  this.REACT_APP_HEADER_TITLE_SIZE = 30;
};
