import React from "react";
import DateFormat from "../Utilities/DateFormat";
import ListComponent from "../Base/ListComponent";

const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    this.state.urlapi = GlobalConfig.REACT_APP_API_CLASSIFICATION_URL;
    this.state.urllist = process.env.REACT_APP_URL_CLASSIFICATION_LIST;
    this.state.urladd = process.env.REACT_APP_URL_CLASSIFICATION_ADD;
    this.state.title = "Classification";

    //"/classifications/item-add/"
    this.state.urlvarietyadd = process.env.REACT_APP_URL_CLASSIFICATION_ITEM_ADD + "/";
  }

  setColumns = () => {
    return [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        cell: (row) => (
          <a href={this.state.urllist + "/" + row._id} title={row.name}>
            {row.name}
          </a>
        ),
      },
      {
        name: "Classification Items",
        selector: "",
        cell: (row) => (
          <a
            href={this.state.urllist + "/classifications/" + row._id + ""}
            title="Classification Items"
          >
            Classification Items{" "}
            {row.countClassificationItems > 0 ? "(" + row.countClassificationItems + ")" : ""}
          </a>
        ),
      },
      {
        name: "Modified Date",
        selector: "modifiedDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return row.modifiedDate ? (
            <DateFormat date={row.modifiedDate}></DateFormat>
          ) : (
            ""
          );
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return <DateFormat date={row.createdDate}></DateFormat>;
        },
      },
      {
        name: "",
        cell: (row) => (
          <div>
            <a
              href={this.state.urllist + "/" + row._id}
              className="on-default edit-row"
            >
              <i className="fa fa-pencil-square-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            <a
              title="Click here to remove"
              href="#"
              className="on-default remove-row"
              data-id={row._id}
              onClick={this.handleDelete.bind(this)}
            >
              <i className="fa fa-trash-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            <a
              className="on-default remove-row"
              data-id={row._id}
              href={this.state.urlvarietyadd + row._id}
              title="Click here to create new a variety"
            >
              {/* <i className="fa fa-plus"></i> */}
              &nbsp;&nbsp;Add Classification Item
            </a>
          </div>
        ),
      },
    ];
  };
}
export default App;
