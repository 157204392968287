import React, { Component } from "react";
import Select from "react-select";

export default class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      selectedValue: props.isMulti ? [] : null,
    };
  }

  async componentDidMount() {
    //Query data
    this.getItems();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.api !== this.props.api) {
      this.getItems();
    }

    //Update value from parent
    if (previousProps.value !== this.props.value) {
      this.updateValue();
    }
  }

  updateValue() {
    //Based on value is sent from parent then set state for selectedValue
    if (this.props.isMulti) {
      if (this.props.value && this.props.value.trim()) {
        const values = this.props.value.split(",");
        let temp = [];
        values.forEach((value) => {
          const existItem = [...this.state.items].find(
            (item) => item.value === value
          );
          if (existItem) temp.push(existItem);
        });
        this.setState({
          selectedValue: temp,
        });
      } else {
        this.setState({
          selectedValue: [],
        });
      }
    } else {
      if (this.props.value && this.props.value.trim()) {
        const existItem = [...this.state.items].find(
          (item) => item.value === this.props.value
        );
        this.setState({
          selectedValue: existItem,
        });
      } else {
        this.setState({
          selectedValue: null,
        });
      }
    }
  }

  clearValue = () => {
    this.setState({
      selectedValue: this.props.isMulti ? [] : null,
    });
  };

  getItems = async () => {
    if (this.props.api && this.props.api.trim()) {
      const result = await fetch(this.props.api);
      const response = await result.json();
      if (
        response.result === "OK" &&
        response.data &&
        response.data.length > 0
      ) {
        this.setState(
          {
            items: response.data.map((dataItem) => {
              return this.props.parseObjectItem
                ? this.props.parseObjectItem(dataItem)
                : {
                    label: dataItem.name,
                    value: dataItem._id,
                  };
            }),
          },
          () => this.updateValue()
        );
      }
    } else {
      this.setState(
        {
          items: this.props.defaultOptions ? this.props.defaultOptions : [],
        },
        () => this.updateValue()
      );
    }
  };

  onChange = (e) => {
    this.setState({
      selectedValue: e,
    });
    this.props.onChange(e);
  };

  render() {
    return (
      <Select
        options={this.state.items}
        placeholder={
          this.props.placeholder ? this.props.placeholder : "Select..."
        }
        readOnly
        closeMenuOnSelect={this.props.closeMenuOnSelect}
        isMulti={this.props.isMulti}
        value={this.state.selectedValue}
        onChange={this.onChange}
        isOptionDisabled={() => this.props.isOptionDisabled}
        isDisabled={this.props.isDisabled}
      />
    );
  }
}
