import React from "react";
import SummaryAdmin from "./Report/SummaryAdmin";
import { getLoggedUser } from "../context/auth";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state = {
      role: loggedUser.role,
      view: "",
      data: {},
    };
    this.renderSwitch = this.renderSwitch.bind(this);
  }

  async componentDidMount() {
    this.getItems();
  }

  getItems = async () => {
    try {
      const urlApi = `${GlobalConfig.REACT_APP_API_DASHBOARD_URL}summary`;

      const fetchItem = await fetch(urlApi);
      const response = await fetchItem.json();

      if (response && response.result === "OK" && response.data) {
        this.setState({ data: response.data });
        this.setState({ view: this.renderSwitch() });
      } else {
        console.log(response.message);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  renderSwitch() {
    switch (loggedUser.role) {
      case "Super Administrator":
        return (
          <>
            <SummaryAdmin data={this.state.data}></SummaryAdmin>
          </>
        );
      default:
        return "";
    }
  }

  render() {
    return (
      <section role="main" className="content-body">
        <header className="page-header">
          <h2>Dashboard</h2>
          <div className="right-wrapper pull-right">
            <ol className="breadcrumbs">
              <li>
                <a href="/">
                  <i className="fa fa-home"></i>
                </a>
              </li>
              <li>
                <span>Dashboard</span>
              </li>
            </ol>

            <a className="sidebar-right-toggle" data-open="sidebar-right">
              <i className="fa fa-chevron-left"></i>
            </a>
          </div>
        </header>
        {this.state.view == undefined ||
        this.state.view == null ||
        this.state.view == ""
          ? ""
          : this.state.view}
      </section>
    );
  }
}
export default App;
