const isMatch = require("date-fns").isMatch;
const moment = require("moment");
const FileSaver = require("file-saver");
const XLSX = require("xlsx");

function numberWithCommas(n) {
  var parts = n.toString().split(".");
  const result =
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] ? "." + parts[1] : "");
  return result;
}

function numberWithCommasExt(n) {
  const value =
    n == null || n == undefined || n == isNaN(n) ? 0 : parseFloat(n).toFixed(1);
  var parts = value.toString().split(".");
  const result =
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] ? "." + parts[1] : "");
  return result;
}

function numberWithCommasNegative(n) {
  var parts = n.toString().split(".");
  const result =
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] ? "." + parts[1] : "");
  return n < 0 ? "(" + result.replace("-", "") + ")" : result;
}

function getSortOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

function isValidDate(date, needConvert) {
  try {
    if (date === null || date === undefined || date.trim() === "") {
      return false;
    }
    if (needConvert) {
      date = moment(date).format("MM/DD/YYYY");
    }
    var dateRegex =
      /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/((19|20)\d{2}|(29)\d{2})$/;
    if (!dateRegex.test(date)) {
      //validate enough number of MM/DD/YYYY
      return false;
    }
    if (!moment(date, "MM/DD/YYYY", true).isValid()) {
      return false;
    }
    if (isMatch(date, "MM/dd/yyyy") !== true) {
      //validate Feb, leap year
      return false;
    }
    return true;
  } catch (err) {
    console.log(err.message);
    return false;
  }
}

function isValidEmail(email) {
  if (process.env.REACT_APP_EMAIL_VALIDATE !== true) {
    return ``;
  }
  if (email && email.trim() !== "") {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(email)) {
      return "Please enter valid email address.";
    }
  }
  return "";
}

function exportExcel(fileName, exportedData) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const ws = XLSX.utils.json_to_sheet(exportedData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName);
}

function getQuestionAndAnswerColumns(gameRecords) {
  const columns = [];
  const questionCounts = gameRecords.map((gameRecord) => {
    if (!gameRecord.listOfQuestionsAndAnswers) {
      return 0;
    }
    const questions = JSON.parse(gameRecord.listOfQuestionsAndAnswers);
    if (Array.isArray(questions)) {
      return questions.length;
    } else {
      return 0;
    }
  });
  const maxCount = Math.max(...questionCounts);
  for (let i = 1; i <= maxCount; i++) {
    columns.push(`Question ${i} attempt`);
    columns.push(`Question ${i} respond`);
  }
  return columns;
}

function appendQuestionAndAnswerProperties(
  gameRecord,
  listOfQuestionsAndAnswers
) {
  if (!listOfQuestionsAndAnswers) {
    return;
  }
  const questions = JSON.parse(listOfQuestionsAndAnswers);
  if (Array.isArray(questions) && questions.length) {
    questions.forEach((a, index) => {
      gameRecord[`Question ${index} attempt`] = a.attempt;
      gameRecord[`Question ${index} respond`] = a.respond
        ? a.respond.join(", ")
        : "";
    });
  }
}

const common = {
  numberWithCommas: numberWithCommas,
  numberWithCommasExt: numberWithCommasExt,
  numberWithCommasNegative: numberWithCommasNegative,
  getSortOrder: getSortOrder,
  isValidDate: isValidDate,
  isValidEmail: isValidEmail,
  exportExcel: exportExcel,
  getQuestionAndAnswerColumns: getQuestionAndAnswerColumns,
  appendQuestionAndAnswerProperties: appendQuestionAndAnswerProperties,
};
export default common;
