import React from "react";
import DateFormat from "../Utilities/DateFormat";
import ListComponent from "../Base/ListComponent";

const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    this.state.urlapi = GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL;
    this.state.urllist = process.env.REACT_APP_URL_SYSTEMCONFIG_LIST;
    this.state.urladd = process.env.REACT_APP_URL_SYSTEMCONFIG_LIST + "-add";
    this.state.title = "System Setting";
  }

  setColumns = () => {
    return [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        cell: (row) => (
          <a href={this.state.urllist + "/" + row._id} title={row.name}>
            {row.name}
          </a>
        ),
      },
      {
        name: "Value",
        selector: "value",
        sortable: true
      },
      {
        name: "Modified Date",
        selector: "modifiedDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return row.modifiedDate ? (
            <DateFormat date={row.modifiedDate}></DateFormat>
          ) : (
            ""
          );
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return <DateFormat date={row.createdDate}></DateFormat>;
        },
      },
      {
        name: "",
        center: true,
        cell: (row) => (
          <div>
            <a
              href={this.state.urllist + "/" + row._id}
              className="on-default edit-row"
            >
              <i className="fa fa-pencil-square-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            <a
              className="on-default remove-row"
              data-id={row._id}
              onClick={this.handleDelete.bind(this)}
              title="Click here to remove"
              href="#"
            >
              <i className="fa fa-trash-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </div>
        ),
      },
    ];
  };
}
export default App;
