import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/auth";
import jwt_decode from "jwt-decode";

var jwt = require("jsonwebtoken");
const GlobalConfig = new window.globalConfig();

function PrivateRoute({ component: Component, roles, navRoute, ...rest }) {
  const { authTokens } = useAuth();
  let isExpired = false;
  const now = new Date();
  if (authTokens) {
    const authTokensJSON = jwt_decode(authTokens);
    if (now.getTime() > authTokensJSON.expiry) {
      isExpired = true;
      localStorage.removeItem("tokens");
      localStorage.removeItem("isActive");
    } else {
      //refresh expiry
      const now = new Date();
      const expiry = new Date(
        now.getTime() +
          parseInt(GlobalConfig.REACT_APP_LOGIN_EXPIRY) * 60 * 1000
      ); // convert n minutes to milliseconds
      authTokensJSON.expiry = expiry.getTime();

      const token = jwt.sign(
        authTokensJSON,
        process.env.REACT_APP_TOKEN_SECRET
      );
      localStorage.setItem("tokens", JSON.stringify(token));
    }
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        authTokens && !isExpired ? (
          <Component {...props} navRoute={navRoute} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { referer: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
