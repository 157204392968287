import React, { useState, useEffect } from "react";
import axios from "axios";
import { Error } from "../../Components/AuthForms";
import Alert from "../Utilities/Alert";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import * as Yup from "yup";
import { useFormik } from "formik";

const GlobalConfig = new window.globalConfig();

function App(props) {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Email is required!");

  //USING FOR ALERT
  const [alertshow, setAlertShow] = useState(false);
  const [hideControl, setHideControl] = useState(false);
  const [alerttype, setAlertType] = useState("success");
  const [alertmsg, setAlertMsg] = useState("");
  const params = queryString.parse(props.location.search);
  const token = params.token;
  const timeExpired = params.timeExpired;
  const now = new Date();

  useEffect(() => {

    if (!timeExpired || now > new Date(timeExpired)) {
      setAlertMsg("Request is expired.");
      setAlertType("error");
      setAlertShow(true);
      setHideControl(true);
    }else{
      validateToken();
    }
  }, [timeExpired]);
  function validateToken(){
    axios
      .post(GlobalConfig.REACT_APP_API_AUTH_URL + "/validateTokenResetPassword", {token})
      .then((result) => {
        if (result.status === 200) {
          if (result.data.result === "ERROR") {
            setAlertShow(false);
            setAlertMsg(result.data.message);
            setAlertType("error");
            setAlertShow(true);
            setHideControl(true);
          } else if(result.data.result === "DONE"){
            setAlertShow(false);
            setAlertMsg(result.data.message);
            setAlertType("info");
            setAlertShow(true);
            setHideControl(true);
          }
        }
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setIsError(true);
        console.log(err.message);
      });
  }
  function handleServer(params, resetForm) {
    axios
      .post(GlobalConfig.REACT_APP_API_AUTH_URL + "/reset", {newPassword: params.newPassword, newPasswordRepeat: params.newPasswordRepeat, token})
      .then((result) => {
        if (result.status === 200) {
          if (result.data.result === "OK") {
            resetForm();
            setAlertMsg(`Reset password is successful! Please try to login <a href="/login"><strong>here</strong></a>`);
            setAlertType("success");
            setAlertShow(true);
            setHideControl(true);
            setIsError(false);
          } else {
            setAlertShow(false);
            setAlertMsg(result.data.message);
            setAlertType("error");
            setAlertShow(true);
          }
        }
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setIsError(true);
        console.log(err.message);
      });
  }

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      newPasswordRepeat: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8, 'Password should be 8 chars minimum.')
        .required('New Password required'),
      newPasswordRepeat: Yup.string()
        .required('Confirm Password required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    }),onSubmit: (values, actions) => {
      handleServer(values, () => {
        actions.resetForm({
          values: {
            newPassword: '',
            newPasswordRepeat: '',
          }
        });
      });
      
    },
  });


  return (
    <div
      style={{
        backgroundImage: "url(/assets/images/background-minds.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Helmet>
        <title>
          {"Reset Password | " + process.env.REACT_APP_SITE_TITLE}
        </title>
      </Helmet>
      <div>
        <section className="body-sign">
          <div className="center-sign">
            <a href="/" className="logo pull-left pt-xl">
              <img
                src="/assets/images/logo-minds.png"
                height="30"
                alt={process.env.REACT_APP_SITE_TITLE}
              />
            </a>

            <div className="panel panel-sign">
              <div className="panel-title-sign mt-xl text-right">
                <h2 className="title text-uppercase text-bold m-none">
                  <i className="fa fa-user mr-xs"></i> Reset Password
                </h2>
              </div>
              <div className="panel-body">
                {alertshow === true ? (<Alert message={alertmsg} type={alerttype} show={alertshow}></Alert>) : ("")}
                {isError && (<Error className="form-group mb-lg">{ReactHtmlParser(errorMessage)}</Error>)}
                {hideControl ? "" : 
                (
                  <>
                    <div className="form-horizontal" id="form">
                      <div className={ formik.touched.newPassword && formik.errors.newPassword ? "form-group has-error" : "form-group" }>
                        <label className="col-md-4 control-label" htmlFor="newPassword" >New Password</label>
                        <div className="col-md-8">
                          <input
                            type="password"
                            className="form-control"
                            id="newPassword"
                            name="newPassword"
                            maxLength={100}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.newPassword}
                          ></input>
                          {
                            formik.touched.newPassword && formik.errors.newPassword ? (
                            <label for="name" className="control-label">
                              {formik.errors.newPassword}
                            </label>
                            ) : null
                          }
                        </div>
                      </div>
                      <div className={ formik.touched.newPasswordRepeat && formik.errors.newPasswordRepeat ? "form-group has-error" : "form-group" }>
                        <label className="col-md-4 control-label" htmlFor="newPasswordRepeat">Confirm Password</label>
                        <div className="col-md-8">
                          <input
                            type="password"
                            className="form-control"
                            id="newPasswordRepeat"
                            name="newPasswordRepeat"
                            maxLength={100}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.newPasswordRepeat}
                          ></input>
                          {
                            formik.touched.newPasswordRepeat && formik.errors.newPasswordRepeat ? (
                            <label for="name" className="control-label">
                              {formik.errors.newPasswordRepeat}
                            </label>
                            ) : null
                          }
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 text-right ">
                          <button onClick={formik.handleSubmit}  className="btn btn-primary">Reset</button>
                        </div>
                      </div>
                    </div>
                  </>
                )
                
                }
              </div>
            </div>


          </div>
        </section>
      </div>
    </div>
  );
}
export default App;
