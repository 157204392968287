import React from "react";
import DateFormat from "../Utilities/DateFormat";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    this.state = {
      ...this.state,
      urlapi: `${ GlobalConfig.REACT_APP_API_USER_URL}administrators`,
      urlapidelete: `${ GlobalConfig.REACT_APP_API_USER_URL}/`,
      urllist: process.env.REACT_APP_URL_SUPPER_USER_LIST,
      urladd: process.env.REACT_APP_URL_SUPPER_USER_LIST + `-add`,
      title: "Administrator",
    }
  }

  setColumns = () => {
    return [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        cell: (row) => {
          return loggedUser.referenceUserId === row._id ? (
            <a href={process.env.REACT_APP_URL_MY_PROFILE} title={row.name}>
              {row.name}
            </a>
          ) : (
            <a href={process.env.REACT_APP_URL_SUPPER_USER_LIST + "/" + row._id} title={row.name}>
              {row.name}
            </a>
          );
        },
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
        left: true,
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        cell: (row) => {
          return row.status === "Active" ? (
            <span
              className="label label-success"
              style={{ paddingTop: "5px", paddingBottom: "7px", width: "55px" }}
            >
              {row.status}
            </span>
          ) : (
            <span
              className="label label-danger"
              style={{ paddingTop: "5px", paddingBottom: "7px", width: "55px" }}
            >
              {row.status}
            </span>
          );
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return <DateFormat date={row.createdDate}></DateFormat>;
        },
      },
      {
        name: "",
        cell: (row) => (
          <div>
            {loggedUser.referenceUserId === row._id ? (
              <a
                href={process.env.REACT_APP_URL_MY_PROFILE}
                className="on-default edit-row"
              >
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </a>
            ) : (
              <a
                href={process.env.REACT_APP_URL_SUPPER_USER_LIST + "/" + row._id}
                className="on-default edit-row"
              >
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </a>
            )}
            &nbsp;&nbsp;
            {loggedUser.referenceUserId === row._id ? (
              ""
            ) : (
              <a
                className="on-default remove-row"
                data-id={row._id}
                onClick={this.handleDelete.bind(this)}
                title="Click here to remove"
                href="#"
              >
                <i className="fa fa-trash-o fa-lg"></i>
              </a>
            )}
          </div>
        ),
      },
    ];
  };
}
export default App;
