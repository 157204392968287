import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "../../context/auth";
import { Error } from "../../Components/AuthForms";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
var jwt = require("jsonwebtoken");
const GlobalConfig = new window.globalConfig();

function Login(props) {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(
    "Username and password is required!"
  );
  const { setAuthTokens } = useAuth();

  function postLogin(props) {
    if (
      userName === undefined ||
      userName === "" ||
      userName === "undefined" ||
      password === undefined ||
      password === "" ||
      password === "undefined"
    ) {
      setErrorMessage("Username and password is required!");
      setIsError(true);
    } else {
      axios
        .post(GlobalConfig.REACT_APP_API_AUTH_URL + "/login", {
          userName,
          password,
        })
        .then((result) => {
          console.log('result', result);
          if (result.status === 200) {
            if (result.data.result != "OK") {
              setErrorMessage(result.data.message);
              setIsError(true);
            } else {
              let avatar = result.data.data.avatar;
              if (
                avatar != undefined &&
                avatar != "undefined" &&
                avatar != "" &&
                avatar != process.env.REACT_APP_DEFAULT_COMPANY_PHOTO &&
                avatar != process.env.REACT_APP_DEFAULT_AVATAR_PHOTO
              ) {
                avatar = process.env.REACT_APP_API_URL + avatar;
                result.data.data.avatar = avatar;
              }

              const now = new Date()
              let dataJSON = JSON.parse(JSON.stringify(result.data.data));
              const expiry = new Date(
                now.getTime() + (parseInt(GlobalConfig.REACT_APP_LOGIN_EXPIRY) * 60 * 1000));// convert n minutes to milliseconds

              dataJSON.expiry = expiry.getTime();

              const token = jwt.sign(
                dataJSON,
                process.env.REACT_APP_TOKEN_SECRET
              );
              localStorage.clear();
              localStorage.setItem("isActive", result.data.data.isActive);
              setAuthTokens(token);
              setLoggedIn(true);
            }
          } else {
            setErrorMessage(
              "The username or password provided were incorrect!"
            );
            setIsError(true);
          }
        })
        .catch((e) => {
          setErrorMessage("The username or password provided were incorrect!");
          setIsError(true);
        });
    }
  }

  try {
    const referer = props.location.state.referer || "/";
    if (isLoggedIn) {
      if (`${localStorage.getItem("isActive")}` === 'false') {
        return <Redirect to="/change-password" />;
      }
      return <Redirect to={referer} />;
    }
  } catch {
    return <Redirect to="/" />;
  }

  return (
    <div
      style={{
        backgroundImage:
          "url(/assets/images/background-minds.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Helmet>
        <title>{"Home | " + process.env.REACT_APP_SITE_TITLE}</title>
      </Helmet>
      <section className="body-sign">
        <div className="center-sign">
          <a href="/" className="logo pull-left pt-xl">
            <img src="/assets/images/logo-minds.png" height="30" alt="Porto Admin" />
          </a>

          <div className="panel panel-sign">
            <div className="panel-title-sign mt-xl text-right">
              <h2 className="title text-uppercase text-bold m-none">
                <i className="fa fa-user mr-xs"></i> Sign In
              </h2>
            </div>
            <div className="panel-body">
              {isError && (
                <Error className="form-group mb-lg">{errorMessage}</Error>
              )}
              <div className="form-group mb-lg">
                <label>Username</label>
                <div className="input-group input-group-icon">
                  <input
                    required
                    name="username"
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                    autoFocus
                    placeholder="E-mail"
                    type="text"
                    maxLength={100}
                    className="form-control input-lg"
                  />
                  <span className="input-group-addon">
                    <span className="icon icon-lg">
                      <i className="fa fa-user"></i>
                    </span>
                  </span>
                </div>
              </div>

              <div className="form-group mb-lg">
                <div className="clearfix">
                  <label className="pull-left">Password</label>
                  <a href="/recover-password" className="pull-right">
                    Lost Password?
                  </a>
                </div>
                <div className="input-group input-group-icon">
                  <input
                    required
                    name="password"
                    type="password"
                    maxLength={100}
                    className="form-control input-lg"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <span className="input-group-addon">
                    <span className="icon icon-lg">
                      <i className="fa fa-lock"></i>
                    </span>
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-8">
                  {/* <div className="checkbox-custom checkbox-default">
                  <input id="RememberMe" name="rememberme" type="checkbox" />
                  <label for="RememberMe">Remember Me</label>
                </div> */}
                </div>
                <div className="col-sm-4 text-right">
                  <button
                    type="button"
                    className="btn btn-primary hidden-xs"
                    onClick={postLogin}
                  >
                    Sign In
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-block btn-lg visible-xs mt-lg"
                    onClick={postLogin}
                  >
                    Sign In
                  </button>
                </div>
              </div>
              {/* <div className="row">
                <hr className="dotted short"></hr>
                <p className="text-center">
                  Don't have an account yet?{" "}
                  <a href={process.env.REACT_APP_URL_SIGNUP}>Sign Up!</a>
                </p>
              </div> */}
            </div>
          </div>
          <p className="text-center text-muted mt-md mb-md">
            <span style={{ color: "white" }}>
              &copy; MINDS Serious Games
            </span>
          </p>
        </div>
      </section>
    </div>
  );
}
export default Login;
