import React from "react";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { Helmet } from "react-helmet";
import { getLoggedUser } from "../../context/auth";

let loggedUser = {};
class App extends React.Component {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state = {
      title: "",
      listtitle: "",
      urllist: "",
      urlapi: "",
      urlsubmitapi: "",
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      imageFieldName: "",
      imageFieldExtension: "",
      imageMaxSize: 0,
      data: {},
      isUseHeading: true,
      
      // custom class
      panelClassName: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChange4Image = this.handleChange4Image.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  async componentDidMount() {
    this.getItemById(this.state.data._id);
  }

  getItemById = async (id) => {
    this.setState({
      alertmsg: "Query data...",
      alerttype: "info",
      alertshow: true,
    });
    try {
      var url = this.composeQueryUrlApi(id);
      const fetchItem = await fetch(url);
      const item = await fetchItem.json();
      if (item && item.result == "OK") {
        if (item.data) {
          this.setState({
            data: item.data,
          });
          //Turn-off message
          this.setState({
            alertshow: false,
          });
          this.doSomethingAfterGetItemById();
        } else {
          // window.location.href = process.env.REACT_APP_URL_PAGE_NOT_FOUND;
          // this.setState({
          //   alertshow: false,
          // });
          // this.setState({
          //   alertmsg: item.message,
          //   alerttype: "error",
          //   alertshow: true,
          // });
        }
      } else {
        this.setState({
          alertshow: false,
        });
        this.setState({
          alertmsg: item.message,
          alerttype: "error",
          alertshow: true,
        });
      }
    } catch (err) {
      this.setState({
        alertshow: false,
      });
      this.setState({
        alertmsg: err.message,
        alerttype: "error",
        alertshow: true,
      });
    }
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error on getBase64: ", error);
    };
  }

  handleChange4Image(event) {
    var id = event.target.name;
    // event.target.files[0];
    var files = event.target.files;
    if (files && files.length > 0) {
      files.forEach((file) => {
        if (file.type == "image/jpeg" || file.type == "image/png") {
          // if (parseInt(files.size.split(" ")[0]) > this.state.imageMaxSize) {
          //   window.showAlert(
          //     "Error",
          //     "Does not allow image over " + this.state.imageMaxSize + " KB. Please upload another.",
          //     "error"
          //   );
          //   return;
          // }
          let filesBase64 = "";
          this.getBase64(file, (result) => {
            filesBase64 = result;
            const arrTemp = file.name.split(".");
            const fileExtension = arrTemp[arrTemp.length - 1];
            this.setState({
              data: Object.assign({}, this.state.data, {
                [this.state.imageFieldName]: filesBase64,
                [this.state.imageFieldExtension]: fileExtension,
              }),
            });
          });
        } else {
          window.showAlert("Error", "Please choose PNG or JPG", "error");
        }
      });
    } else {
      this.setState({
        data: Object.assign({}, this.state.data, {
          [this.state.imageFieldName]: "",
        }),
      });
    }
  }

  handleChange(event) {
    var id = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    // console.log(id);
    // console.log(value);
    this.setState({
      data: Object.assign({}, this.state.data, {
        [id]: value,
      }),
    });
  }

  handleImageChange(files) {
    //files
    //   {
    //     "name": "logo_react.jpg",
    //     "type": "image/jpeg",
    //     "size": "57 kB",
    //     "base64": "data:image/jpeg;base64,/9j/4AAQSkZJRg...",
    //     "file": {}
    //   }
    // ]
    if (files) {
      if (files.type == "image/jpeg" || files.type == "image/png") {
        // if (parseInt(files.size.split(" ")[0]) > this.state.imageMaxSize) {
        //   window.showAlert(
        //     "Error",
        //     "Does not allow image over 1MB. Please upload another.",
        //     "error"
        //   );
        //   return;
        // }
        const arrTemp = files.name.split(".");
        const fileExtension = arrTemp[arrTemp.length - 1];
        this.setState({
          data: Object.assign({}, this.state.data, {
            [this.state.imageFieldName]: files.base64,
            [this.state.imageFieldExtension]: fileExtension,
          }),
        });
      } else {
        window.showAlert("Error", "Please choose PNG or JPG", "error");
      }
    } else {
      this.setState({
        data: Object.assign({}, this.state.data, {
          [this.state.imageFieldName]: "",
        }),
      });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.checkSomethingBeforeSave() === false) {
      return;
    }
    this.setState({
      alertmsg: "Processing...",
      alerttype: "info",
      alertshow: true,
    });

    this.setState(
      {
        data: Object.assign({}, this.state.data, {
          ["modifiedUserId"]: loggedUser._id,
        }),
      },
      async function callBack() {
        try {
          const api =
            this.state.urlsubmitapi === null ||
              this.state.urlsubmitapi === undefined ||
              this.state.urlsubmitapi === ""
              ? this.state.urlapi
              : this.state.urlsubmitapi;
          const requestOptions = {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.state.data),
          };
          const response = await fetch(
            api + this.state.data._id,
            requestOptions
          );

          const resultObject = await response.json();

          //If updated successful
          if (resultObject && resultObject.result === "OK") {
            this.setState({
              alertshow: false,
            });
            window.showAlert("", "Save successful!", "");

            this.doSomethingAfterSaved(resultObject);
          }
          //If failed, show error
          else {
            this.setState({
              alertshow: false,
            });
            this.setState({
              alertmsg: resultObject.message,
              alerttype: "error",
              alertshow: true,
            });
          }
        } catch (err) {
          this.setState({
            alertshow: false,
          });
          this.setState({
            alertmsg: err.message,
            alerttype: "error",
            alertshow: true,
          });
        }
      }
    );
  };

  checkSomethingBeforeSave = () => {
    return true;
  };

  render() {
    const title = this.defaultTitle();
    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation navRoute={this.props.navRoute}></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>
                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    {this.state.urllist == "" || this.state.listtitle == "" ? (
                      ""
                    ) : (
                      <li>
                        <a href={this.state.urllist}>{this.state.listtitle}</a>
                      </li>
                    )}
                    {this.extendBreadcrumb()}
                    <li>
                      <span>{this.getTitle()}</span>
                    </li>
                  </ol>
                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              <div className="row">
                <div className="col-md-12">
                  <form
                    onSubmit={this.handleSubmit}
                    id="form"
                    className="form-horizontal"
                  >
                    <section className={`panel ${this.state.panelClassName}`}>
                      {this.state.isUseHeading ? (
                        <header className="panel-heading">
                          <h2 className="panel-title">
                            {this.state.sectionTitle == undefined
                              ? "Basic Information"
                              : this.state.sectionTitle}
                          </h2>
                        </header>
                      ) : (
                        ""
                      )}
                      {this.renderForm()}
                      {this.renderFooter()}
                    </section>
                  </form>
                  {this.state.alertshow === true ? (
                    <Alert
                      message={this.state.alertmsg}
                      type={this.state.alerttype}
                      show={this.state.alertshow}
                      showBtnClose={true}
                    ></Alert>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {this.extraFooterRender()}
            </section>
          </div>
        </section>
      </>
    );
  }

  renderForm = () => {
    return "";
  };

  extraFooterRender = () => {
    return "";
  };

  renderFooter = () => {
    return (
      <footer className="panel-footer">
        <div className="text-center">
          <button
            type="submit"
            className="btn btn-success"
            style={{ marginLeft: "0px" }}
          >
            <i className="fa fa-save fa-lg"></i> Save
          </button>
          {this.state.urllist === null ||
            this.state.urllist === undefined ||
            this.state.urllist === "" ? (
            ""
          ) : (
            <a
              href={this.state.urllist}
              title="Back to list"
              className="btn btn-primary"
            >
              <i className="fa fa-backward fa-lg"></i> Back
            </a>
          )}
          {this.extendButtons()}
        </div>
      </footer>
    );
  };

  extendBreadcrumb = () => {
    return "";
  };

  extendButtons = () => {
    return "";
  };

  defaultTitle = () => {
    return "Edit - " + this.getTitle();
  };

  getTitle = () => {
    return "";
  };

  doSomethingAfterGetItemById = () => { };

  doSomethingAfterSaved = () => { };

  composeQueryUrlApi = (id) => {
    return this.state.urlapi + id;
  };
}
export default App;
